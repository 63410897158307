import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getEvents = (token, { page = 1, sortBy = "", sortDesc = false, query } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }else{
        params["order"] = 'updated_at'
        params["direction"] = "desc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/events", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getEvent = (token, { id }) => {
    return getRequest(`/admin/events/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveEvent = (token, event) => {
    if (event.id) {
        let { id, ...eventWithoutId } = event
        return patchRequest(`/admin/events/${ id }`, {
            event: eventWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/events", {
          event: event
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteEvent = (token, event) => {
    return deleteRequest(`/admin/events/${ event.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
