<template>
    <div class="content">
        <h1>
            <template v-if="!$route.params.id">
                New Weekly Featured Manufacturer
            </template>

            <template v-else>
                Edit Weekly Featured Manufacturer #{{ $route.params.id }}
            </template>
        </h1>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <b-form-group
                                    :id="`weekly_featured_manufacturer_start_date_fieldset`"
                                    label="Start Date"
                                    :label-for="`weekly_featured_manufacturer_start_date`"
                                    >
                                    <b-form-datepicker
                                    :id="`weekly_featured_manufacturer_start_date_date_picker`"
                                    :state="null"
                                    :value="attributes.start_date"
                                    :reset-button="true"
                                    @input="attributes.start_date = $event || null"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group
                                    :id="`weekly_featured_manufacturer_end_date_fieldset`"
                                    label="End Date"
                                    :label-for="`weekly_featured_manufacturer_end_date`"
                                    >
                                    <b-form-datepicker
                                    :id="`weekly_featured_manufacturer_end_date_date_picker`"
                                    :state="null"
                                    :value="attributes.end_date"
                                    :reset-button="true"
                                    @input="attributes.end_date = $event || null"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-form-group>
                                <b-form-checkbox
                                    :id="`industry_insider_active_checkout`"
                                    v-model="attributes.active"
                                    :value="true"
                                    :unchecked-value="false"
                                    >
                                    Active
                                </b-form-checkbox>
                            </b-form-group>
                        </b-row>
                        <b-row>
                            <b-col>
                            <b-form-group
                                    label="Building Products"
                                >
                                    <b-container>
                                        <b-col
                                            v-for="(link, index) in attributes.featured_manufacturers"
                                            :key="index"
                                            class="mb-2"
                                        >
                                            <b-card no-body class="overflow-hidden">
                                                <b-card-body>
                                                    <b-form-group>
                                                        <multiselect
                                                            :value="link.manufacturer_profile"
                                                            label="text"
                                                            :id="index"
                                                            track-by="value"
                                                            placeholder="Type to search"
                                                            open-direction="bottom"
                                                            :options="link.manufacturer_profiles"
                                                            :multiple="false"
                                                            :searchable="true"
                                                            :loading="isLoadingManufacturerProfiles"
                                                            :internal-search="false"
                                                            :clear-on-select="true"
                                                            :close-on-select="true"
                                                            :options-limit="300"
                                                            :limit="8"
                                                            :limit-text="limitTextManufacturerProfiles"
                                                            :max-height="600"
                                                            :show-no-results="false"
                                                            :hide-selected="false"
                                                            @search-change="asyncFindManufacturerProfiles"
                                                            @input="link.manufacturer_profile = $event; link.manufacturer_profile_id = $event.value"
                                                        >
                                                            <span
                                                                slot="noResult"
                                                            >
                                                                Oops! No elements found. Consider changing the search query.
                                                            </span>
                                                        </multiselect>
                                                        <b-form-checkbox
                                                            :id="`manufacturer_building_products_${index}_active`"
                                                            v-model="link.active"
                                                            :value="true"
                                                            :unchecked-value="false"
                                                            >
                                                            Active
                                                        </b-form-checkbox>
                                                    </b-form-group>
                                                    <b-button
                                                        pill
                                                        size="sm"
                                                        variant="outline-success"
                                                        @click="onBuildingProductAdd(index)"
                                                    >
                                                        Add
                                                    </b-button>
<!--                                                     <b-button
                                                        pill
                                                        size="sm"
                                                        variant="outline-secondary"
                                                        @click="onBuildingProductUp(index)"
                                                    >
                                                        Up
                                                    </b-button>

                                                    <b-button
                                                        pill
                                                        size="sm"
                                                        variant="outline-secondary"
                                                        @click="onBuildingProductDown(index)"
                                                    >
                                                        Down
                                                    </b-button> -->
                                                    
                                                    <b-button
                                                        pill
                                                        size="sm"
                                                        variant="outline-danger"
                                                        @click="onBuildingProductRemove(index)"
                                                        >
                                                        Remove
                                                    </b-button>
                                                </b-card-body>
                                            </b-card>
                                        </b-col>
                                    </b-container>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>


        <b-row>
            <b-col>
                <b-button
                    size="sm"
                    variant="primary"
                    class="px-5"
                    @click="onSave"
                >
                    Save
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { getFeaturedRange, saveFeaturedRange } from "api/admin/featured-ranges"
import { getManufacturers, getManufacturer } from "api/admin/manufacturers"
import Multiselect from "vue-multiselect"
import { mapGetters } from "vuex"

export default {
    components: { Multiselect },

    data () {
        const attributeDefinitions = [
            { name: "id", type: "integer", default: null },
            { name: "start_date", type: "datetime", default: null },
            { name: "end_date", type: "datetime", default: null },
            { name: "active", type: "boolean", default: false },
            { name: "featured_manufacturers", type: "array", default: [{manufacturer_profile: null, manufacturer_profile_id: null, manufacturer_profiles: [], active: false}]}
        ]
        const attributes = attributeDefinitions.reduce((hash, info) => {
            hash[info.name] = info.default
            return hash
        }, {})
        const removeAttributes = attributeDefinitions.reduce((hash, info) => {
            if(info.type == "array"){
                hash[info.name] = []
            }
            return hash
        }, {})

        return {
            attributeDefinitions,
            attributes,
            removeAttributes,
            isLoadingManufacturerProfiles: false,
            record: null,
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    mounted () {
        this.onLoad()
    },

    methods: {
        onLoad () {
            const id = this.$route.params.id
            if (id) {
                getFeaturedRange(this.adminToken, { id })
                    .then(record => {
                        console.log("*** Loaded")
                        console.dir(record)
                        this.record = record
                        this.attributes.id = this.record.id
                        this.attributes.start_date = this.record.start_date
                        this.attributes.end_date = this.record.end_date
                        this.attributes.active = this.record.active
                        // {manufacturer_profile: null, manufacturer_profile_id: null, manufacturer_profiles: [], active: false}
                        this.attributes.featured_manufacturers = this.record.featured_manufacturers
                        this.attributes.featured_manufacturers.forEach((featured_manufacturer,index)=>{
                            featured_manufacturer.manufacturer_profile = featured_manufacturer.manufacturer_profiles[0]
                            if(this.record.featured_manufacturers[index]){
                                featured_manufacturer.id = this.record.featured_manufacturers[index].id
                                featured_manufacturer.active = this.record.featured_manufacturers[index].active
                            }

                        });
                        if(this.attributes.featured_manufacturers.length <= 0){
                            this.attributes.featured_manufacturers = [{manufacturer_profile: null,manufacturer_profile_id: null, manufacturer_profiles: [], active: false}]
                        }
                    })
                    .catch(error => {
                        this.$bvToast.toast(error.message, {
                            title: "Unexpected Error",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    })
            }
        },

        onSave () {
            let record = {}
            record.id = this.attributes.id
            record.start_date = this.attributes.start_date
            record.end_date = this.attributes.end_date
            record.active = this.attributes.active
            record.featured_manufacturers_attributes = []
            this.attributes.featured_manufacturers.forEach(featured_manufacturer => {
                record.featured_manufacturers_attributes.push({
                    id: featured_manufacturer.id || null,
                    manufacturer_profile_id: featured_manufacturer.manufacturer_profile_id,
                    active: featured_manufacturer.active
                });
            });
            this.removeAttributes.featured_manufacturers.forEach(featured_manufacturer => {
                record.featured_manufacturers_attributes.push({
                    id: featured_manufacturer.id || null,
                    manufacturer_profile_id: featured_manufacturer.manufacturer_profile_id,
                    active: featured_manufacturer.active,
                    _destroy: true
                })
            })
            this.saveRecord(record);
        },

        saveRecord (record) {
            saveFeaturedRange(this.adminToken, record)
                .then(newRecord => {
                    const isNewRecord = !record.id
                    this.record = newRecord
                    if (isNewRecord) {
                        this.$router.push({
                            name: "admin-edit-weekly-featured-manufacturer",
                            params: { id: newRecord.id }
                        })
                    } else {
                        this.$bvToast.toast("Succeeded!", {
                            title: "Save status",
                            variant: "success",
                            autoHideDelay: 5000
                        })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        limitTextManufacturerProfiles (count) {
            return `and ${ count } other manufacturers`
        },
        asyncFindManufacturerProfiles (query, id) {
            getManufacturers(this.adminToken, { query })
                .then(result => {
                    const options = result.data.map(entry => {
                        return { value: entry.id, text: `#${entry.id} ${entry.company_name}` }
                    })
                    this.attributes.featured_manufacturers[id].manufacturer_profiles.splice(0, this.attributes.featured_manufacturers[id].manufacturer_profiles.length, ...options)
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unexpected Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error)
                })
        },

        onBuildingProductUp (index) {
            if (index > 0) {
                let item1 = this.attributes.featured_manufacturers[index - 1]
                let item2 = this.attributes.featured_manufacturers[index]
                item1.position = index
                item2.position = index - 1
                this.attributes.featured_manufacturers.splice(index - 1, 1, item2)
                this.attributes.featured_manufacturers.splice(index, 1, item1)
            }
        },

        onBuildingProductDown (index) {
            if (index < this.attributes.featured_manufacturers.length - 1) {
                let item1 = this.attributes.featured_manufacturers[index]
                let item2 = this.attributes.featured_manufacturers[index + 1]
                item1.position = index + 1
                item2.position = index
                this.attributes.featured_manufacturers.splice(index, 1, item2)
                this.attributes.featured_manufacturers.splice(index + 1, 1, item1)
            }
        },

        onBuildingProductAdd (index) {
            this.attributes.featured_manufacturers.splice(index + 1, 0, {manufacturer_profile: null,manufacturer_profile_id: null, manufacturer_profiles: [], active: false})
        },

        onBuildingProductRemove (index) {
            let building_product = this.attributes.featured_manufacturers.splice(index, 1)
            this.removeAttributes.featured_manufacturers.push(building_product[0])
            if (this.attributes.featured_manufacturers.length === 0) {
                this.attributes.featured_manufacturers.splice(0, 0, {manufacturer_profile: null, manufacturer_profile_id: null, manufacturer_profiles: [], active: false})
            }
        },
    }
}
</script>
