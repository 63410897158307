<template>
    <div class="content">
        <edit-columns
            :definitions="attributeDefinitions"
            :record="record"
            @save="onSave"
            title="Building Product"
            :enableTitle="true"
        />
    </div>
</template>

<script>
import { getBuildingProduct, saveBuildingProduct } from "api/admin/building-products"
import { mapGetters } from "vuex"
import EditColumns from "../shared/edit-columns"

export default {
    components: { EditColumns },

    data () {
        const attributeDefinitions = [
            { name: "title", type: "text", default: "" },
            { name: "active", type: "boolean", default: false },
            { name: "image", type: "image", default: undefined },
            { name: "image_source", type: "text", default: "" },
            // { name: "color", type: "color", default: null },
            { name: "color", title: "Text Color", type: "options", default: "", options: [{ value: "#FFFFFF", text: "White" },{ value: "#000000", text: "Black" }] },
            { name: "page_type", type: "options", default: "", options: [{ value: "home", text: "Home" },{ value: "news", text: "News" }] },
        ]

        return {
            attributeDefinitions,
            record: null,
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    mounted () {
        this.onLoad()
    },

    methods: {
        onLoad () {
            const id = this.$route.params.id
            if (id) {
                getBuildingProduct(this.adminToken, { id })
                    .then(record => {
                        console.log("*** Loaded")
                        console.dir(record)
                        this.record = record
                    })
                    .catch(error => {
                        this.$bvToast.toast(error.message, {
                            title: "Unexpected Error",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    })
            }
        },

        onSave (record) {
            saveBuildingProduct(this.adminToken, record)
                .then(newRecord => {
                    const isNewRecord = !record.id
                    this.record = newRecord
                    if (isNewRecord) {
                        this.$router.push({
                            name: "admin-edit-building-product",
                            params: { id: newRecord.id }
                        })
                    } else {
                        this.$bvToast.toast("Succeeded!", {
                            title: "Save status",
                            variant: "success",
                            autoHideDelay: 5000
                        })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },
    }
}
</script>
