<template>
    <b-modal
        :id="id"
        modal-class="signin-modal"
        hide-header
        hide-footer
        centered
    >
        <a
            class="close-button"
            href="#"
            @click.stop.prevent="onClose"
        >
            <b-img
                :src="require('assets/images/modal-close.png')"
                alt="Fluid-grow image"
                width="16"
                height="16"
            />
        </a>

        <div class="d-flex justify-content-center align-items-center" v-if="type == 'firm_admin' || type == 'manufacturer_admin'">
            <b-img
            :src="require('assets/images/logo-small.png')"
            alt="Fluid-grow image"
            width="68"
            height="65"
            />
            <h1 style="text-align:left;margin-left: 10px;margin-bottom: 0;">
                <span>
                    {{ title }}<br>
                    {{ sub_title }}
                </span>
            </h1>
        </div>
        <h1 v-else>
            <b-img
            :src="require('assets/images/logo-small.png')"
            alt="Fluid-grow image"
            width="38"
            height="35"
            />
            {{title}}
        </h1>

        <hr v-show="type != 'firm_admin' && type != 'manufacturer_admin'">
        <br v-show="type == 'firm_admin' || type == 'manufacturer_admin'">

        <div class="content">
            <p class="subscriber" v-show="type != 'firm_admin' && type != 'manufacturer_admin'">
                {{ subscriberMessage }}
            </p>

            <p
                v-show="!!errorMessage"
                class="error"
            >
                {{ errorMessage }}
            </p>

            <b-form @submit.stop.prevent="onSubmit">
                <b-form-input
                    v-model="email"
                    type="email"
                    placeholder="Email Address"
                    required
                    autocomplete="off"
                    autocapitalize="none"
                    autocorrect="off"
                />

                <b-input-group class="mt-3" v-if="!isForgotPassword">
                    <b-form-input
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      placeholder="Password"
                      required
                      autocomplete="off"
                      autocapitalize="none"
                      autocorrect="off"
                    />
                    <b-input-group-append>
                      <b-button class="button" @click="togglePasswordVisibility" variant="outline-secondary" style="height: 92%" ><span class="icon is-small is-right">
                      <b-icon :icon="showPassword ? 'eye-slash' : 'eye'"></b-icon></span></b-button>
                    </b-input-group-append>
                </b-input-group>

                <p class="forgot-password" v-if="isForgotPassword">
                    Log in?
                    <a @click.prevent="signInForm" style="color: #2c4f91; cursor: pointer;">Click Here</a>
                </p>
                <p class="forgot-password" v-else>
                    Forgot your password?
                    <a @click.prevent="forgotYourPassword" style="color: #2c4f91; cursor: pointer;">Click Here</a>
                </p>

                <b-button
                    type="button"
                    variant="primary"
                    squared
                    block
                    @click="submitForgotPassword"
                    v-if="isForgotPassword"
                >
                    Submit
                </b-button>

                <b-button
                    v-else
                    type="submit"
                    variant="primary"
                    squared
                    block
                >
                    Log In
                </b-button>
            </b-form>

            <p class="non-subscriber" v-if="type=='firm_admin'">
                If you are not a registered firm administrator and would like to Profile your design firm or Update your company information, please complete the Design Firm Application and after verification, you will be issued your administration Log In rights.
                <br/><br/>
                There is no cost to post or maintain your Firm listing in the Professional Directory.
            </p>

            <p class="non-subscriber" v-else-if="type=='manufacturer_admin'">
                If you are not a registered manufacturer administrator and would like to start a new lisitng, please complete the Manufacturer Application and after verification, you will be issued your administration log in rights There is no cost to post or maintain a Basic Manufacturer Profile in the Building Product Library.
            </p>
            <p class="non-subscriber" v-else>
            If you are not a Member and want to find out more about our Membership Subscription program, please click below
            </p>

            <b-button
                variant="outline-primary"
                block
                @click="onSubscription"
            >
                {{button_text_info}}
            </b-button>
        </div>
        <!-- <h5></h5> -->
        <!--  -->
        <!-- <a href="" @click.prevent.end="onLoginClicked">Please log in here</a> -->
    </b-modal>
</template>

<script>
import { mapActions } from "vuex"

export default {
    props: {
        type: {
            type: String,
            default: "visiting",
            validator: value => [
                "visiting",
                "favorites",
                "saveFavorites",
                "requestInfo",
                "requestBid",
                "requestSample",
                "admin",
                "manufacturer_admin",
                "firm_admin"
            ].indexOf(value) !== -1
        },
        id: {
            type: String,
            default: "signin_modal",
        }
    },

    data () {
        return {
            isForgotPassword: false,
            email: null,
            password: null,
            passwordConfirmation: null,
            currentPassword: null,
            errorMessage: null,
            titles: {
                firm_admin: "Design Firm Profile",
                manufacturer_admin: "Manufacturer Profile",
                visiting: "Thank you for visiting.",
                favorites: "Become Member To See Favorites.",
                saveFavorites: "Login to Save Favorites",
                requestInfo: "Request Info + Catalog",
                requestBid: "Request Quote or Bid",
                requestSample: "Request Sample",
                admin: "Thank you for visiting. "
            },
            sub_titles: {
                firm_admin: "Administrator Log In",
                manufacturer_admin: "Administrator Log In"
            },
            button_text_infos: {
                firm_admin: "Design Firm Application",
                manufacturer_admin: "Manufacturer Application",
                visiting: "Membership info",
                favorites: "Membership info",
                saveFavorites: "Membership info",
                requestInfo: "Membership info",
                requestBid: "Membership info",
                requestSample: "Membership info",
                admin: "Membership info"
            },
            visitingAdmin: "Welcome back Admin, please Log In here",
            visitingSubscriber: "Welcome back Member, please Log In here",
            otherSubscriber: "You must be a member to utilize this service.",
            showPassword: false,

        }
    },

    computed: {
        title () { return this.titles[this.type] },
        sub_title () { return this.sub_titles[this.type] },
        button_text_info () { return this.button_text_infos[this.type]},
        subscriberMessage () { 
            if(this.type === "admin"){
               return this.visitingAdmin
               
            }
            else if (this.type === "visiting") {
                return this.visitingSubscriber
            }
            else {
                return this.otherSubscriber
            }
        }
    },

    methods: {
        ...mapActions("account", ["signin", "sendResetPassword"]),

        submitForgotPassword () {
            this.sendResetPassword({
                email: this.email
                }).then(() => {
                    this.$bvToast.toast("Please find the reset password email in your inbox!", {
                        title: "Email send!",
                        variant: "success",
                        autoHideDelay: 5000
                    });
                    window.location.reload();
                }).catch(error => {
                this.$bvToast.toast(error.message, {
                    title: "Unexpected Error",
                    variant: "danger",
                    autoHideDelay: 5000
                })
            })
        },

        forgotYourPassword () {
            this.isForgotPassword = true;
        },

        signInForm () {
            this.isForgotPassword = false;
        },

        onClose () {
            console.log("admin clicked....", this.type)
            this.$bvModal.hide(this.id)
        },

        onSubmit () {
            this.signin({ email: this.email, password: this.password })
                .then(() => {
                    this.$bvModal.hide(this.id)
                    this.$forceUpdate();
                    this.$router.go();
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unexpected Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    this.errorMessage = error.message
                })
        },

        onSubscription () {
            this.$bvModal.hide(this.id)
            if(this.type == 'manufacturer_admin'){
                this.$router.push({ name: "showcase-your-products" })
            }else if(this.type == 'firm_admin'){
                this.$router.push({ name: "firm-application-confirmation" })
            }else{
                this.$router.push({ name: "become-a-member" })
            }
        },

        togglePasswordVisibility() {
          this.showPassword = !this.showPassword;
        },
    },
}
</script>

<style lang="scss" scoped>
    .close-button {
        position: absolute;
        top: 18px;
        right: 22px;

        &:hover {
            opacity: 0.7;
        }
    }

    h1 {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.65;
        color: #000;
        text-align: center;
        margin-bottom: 35px
    }

    hr {
        margin-bottom: 42px;
        border-top: 2px solid #979797;
    }

    .content {
        margin: 0 80px;

        .btn {
            text-transform: uppercase;
        }

        .b-button {
            text-transform: uppercase;
        }

        .btn-primary {
            text-transform: uppercase;
        }


    }
    .btn {
            text-transform: uppercase;
        }

        .b-button {
            text-transform: uppercase;
        }

        .btn-primary {
            text-transform: uppercase;
        }

    p {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29;
        color: #000;
        text-align: center;
        margin: 0;

        

        &.subscriber {
            font-style: italic;
            margin-bottom: 22px;
        }

        &.error {
            color: #ff8888;
            margin-bottom: 10px;
        }

        &.forgot-password {
            font-style: italic;
            margin-top: 4px;
            margin-bottom: 18px;
        }

        &.non-subscriber {
            margin-top: 35px;
            margin-bottom: 22px;
        }

        a {
            color: #225a90;
            text-decoration: underline;
        }
    }
</style>
