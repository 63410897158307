<template>
    <div class="content">
        <edit-columns
            :definitions="attributeDefinitions"
            :record="record"
            @save="onSave"
            @delete="onClickDelete"
            title="Featured Manufacturer"
            :enableTitle="true"
            :enableDelete="true"
        />
    </div>
</template>

<script>
import { getFeaturedManufacturer, saveFeaturedManufacturer, deleteFeaturedManufacturer } from "api/admin/featured-manufacturers"
import { mapGetters } from "vuex"
import EditColumns from "../shared/edit-columns"

export default {
    components: { EditColumns },

    data () {
        const attributeDefinitions = [
            { name: "manufacturer_profile_id", type: "manufacturer_profile", default: undefined },
            { name: "end_date", type: "date", default: null },
            { name: "ignore_end_date", type: "boolean", default: true },
            { name: "active", title: 'Show', type: "boolean", default: null },
        ]

        return {
            attributeDefinitions,
            record: null,
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    mounted () {
        this.onLoad()
    },

    methods: {
        onLoad () {
            const id = this.$route.params.id
            if (id) {
                getFeaturedManufacturer(this.adminToken, { id })
                    .then(record => {
                        console.log("*** Loaded")
                        console.dir(record)
                        this.record = record
                    })
                    .catch(error => {
                        this.$bvToast.toast(error.message, {
                            title: "Unexpected Error",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    })
            }
        },

        onSave (record) {
            record.featured_range_attributes = {end_date: record.end_date, active: record.ignore_end_date}
            delete record.end_date
            delete record.ignore_end_date
            saveFeaturedManufacturer(this.adminToken, record)
                .then(newRecord => {
                    const isNewRecord = !record.id
                    this.record = newRecord
                    if (isNewRecord) {
                        this.$router.push({
                            name: "admin-featured-manufacturers",
                            params: { id: newRecord.id }
                        })
                    } else {
                        this.$bvToast.toast("Succeeded!", {
                            title: "Save status",
                            variant: "success",
                            autoHideDelay: 5000
                        })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onClickDelete () {
            const message = `Are you sure you want to delete “${ this.record.id }”? This record will be deleted immediately. You can't undo this action.`

            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
                .then(isConfirmed => {
                    if (isConfirmed) {
                        deleteFeaturedManufacturer(this.adminToken, this.record)
                            .then(() => {
                                this.$nextTick(() => {
                                    this.$router.push({ name: "admin-featured-manufacturers" })
                                })
                            })
                            .catch(error => {
                                this.$bvToast.toast(error.message, {
                                    title: "Unexpected Error",
                                    variant: "danger",
                                    autoHideDelay: 5000
                                })
                            })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unknown error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        }
    }
}
</script>
