import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getNewsletterSubscriptions = (token, { page = 1, sortBy = "", sortDesc = false, filters = {}, tab = {}, query } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (filters) {
        params["filters"] = filters
    }
    if (tab) {
        params['tab'] = tab
    }

    if(query) {
        params["query"] = query
    }
    return getRequest("/admin/newsletter_subscriptions", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getNewsletterSubscription = (token, { id }) => {
    return getRequest(`/admin/newsletter_subscriptions/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const exportNewsletterSubscription = (token, {operation = 'export', requestIds = []}) => {
    let params = {operation}
    if(!!requestIds.length){
        params['requestIds'] = requestIds
    }
    return getRequest(`/admin/newsletter_subscriptions`, params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveNewsletterSubscription = (token, newsletter_subscription) => {
    if (newsletter_subscription.id) {
        let { id, ...NewsletterSubscriptionWithoutId } = newsletter_subscription
        return patchRequest(`/admin/newsletter_subscriptions/${ id }`, {
            newsletter_subscription: NewsletterSubscriptionWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/newsletter_subscriptions", {
            newsletter_subscription: newsletter_subscription
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteNewsletterSubscription = (token, newsletter_subscription) => {
    return deleteRequest(`/admin/newsletter_subscriptions/${ newsletter_subscription.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
