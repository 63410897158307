<template>
    <div class="content">
        <h1>
            <template>
                <b-row>
                    <b-col md="4" v-if="$route.params.id">
                        Edit Event Issue #{{ $route.params.id }}
                    </b-col>
                    <b-col md="4" v-else>
                        New Event Issue
                    </b-col>
                    <b-col md="8">
                        <div class="d-flex justify-content-end">
                            <b-button
                                size="sm"
                                variant="primary"
                                class="px-5 mr-2"
                                @click="onSave('continue')"
                            >
                                Save And Continue Later
                            </b-button>
                            <b-button
                                size="sm"
                                variant="primary"
                                class="px-5"
                                @click="onSave('save')"
                            >
                                Save
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </template>
        </h1>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-body>
                        <b-form-group
                        :id="`industry_insider_issued_on_fieldset`"
                        label="Issued On"
                        :label-for="`industry_insider_issued_on`"
                        >
                            <b-form-datepicker
                            :id="`industry_insider_issued_on_date_picker`"
                            :state="null"
                            :value="attributes.issued_on"
                            :reset-button="true"
                            @input="attributes.issued_on = $event || null"
                            />
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox
                                :id="`industry_insider_active_checkout`"
                                v-model="attributes.active"
                                :value="true"
                                :unchecked-value="false"
                                >
                                Active
                            </b-form-checkbox>
                        </b-form-group>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    label="Event Stories"
                >
                    <b-container class="p-0 m-0">
                        <b-col
                            v-for="(link, index) in attributes.event_stories"
                            :key="index"
                            class="m-0 p-0"
                        >
                            <b-card no-body class="overflow-hidden">
                                <b-row no-gutters>
                                    <b-col md="4">
                                        <b-form-group>
                                            <b-card-img
                                                :src="link.image_path"
                                                alt="Image"
                                                class="rounded-0"
                                                />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="8">
                                        <b-card-body>
                                            <b-form-group>
                                                <b-form-file
                                                    :id="`event_story_${index}_image`"
                                                    :ref="`image_${index}_input`"
                                                    v-model="link.image_picker"
                                                    :state="null"
                                                    placeholder="Choose a file or drop it here..."
                                                    drop-placeholder="Drop file here..."
                                                    :accept="'image/jpeg, image/png'"
                                                    @input="onUpload(index,'image', $event, attributes.event_stories)"
                                                    />

                                                <b-button
                                                    class="mr-2 mt-2"
                                                    @click="onReset(index,'image', attributes.event_stories)"
                                                    >
                                                    Reset
                                                </b-button>
                                            </b-form-group>
                                            <b-form-group
                                                :id="`event_story_${index}_title_fieldset`"
                                                label="title"
                                                :label-for="`event_story_${index}_title`"
                                            >
                                                <b-form-input
                                                    :id="`event_story_${index}_title`"
                                                    v-model="link.title"
                                                    placeholder="Enter title..."
                                                    type="text"
                                                    trim
                                                />
                                            </b-form-group>
                                            <b-form-group
                                                :id="`event_story_${index}_location_fieldset`"
                                                label="location"
                                                :label-for="`event_story_${index}_location`"
                                            >
                                                <b-form-input
                                                    :id="`event_story_${index}_location`"
                                                    v-model="link.location"
                                                    placeholder="Enter location..."
                                                    type="text"
                                                    trim
                                                />
                                            </b-form-group>
                                            <b-form-group
                                                :id="`event_story_${index}_url_fieldset`"
                                                label="url"
                                                :label-for="`event_story_${index}_url`"
                                            >
                                                <b-form-input
                                                    :id="`event_story_${index}_url`"
                                                    v-model="link.url"
                                                    placeholder="Enter url..."
                                                    type="text"
                                                    trim
                                                />
                                            </b-form-group>
                                            <b-form-group
                                            :id="`event_story_${index}_published_at_fieldset`"
                                            label="Date"
                                            :label-for="`event_story_${index}_published_at`"
                                            >
                                                <b-form-datepicker
                                                :id="`event_story_${index}_published_at`"
                                                :state="null"
                                                :value="link.published_at"
                                                :reset-button="true"
                                                @input="link.published_at = $event || null"
                                                />
                                            </b-form-group>
                                            <b-form-group
                                                :id="`event_story_${index}_body_fieldset`"
                                                label="Body"
                                                :label-for="`event_story_${index}_body`"
                                            >
                                                <b-form-textarea
                                                    :id="`event_story_${index}_body`"
                                                    v-model.trim="link.body"
                                                    placeholder="Enter Title..."
                                                    :state="link.body.length > 450 ? false : true"
                                                    type="text"
                                                    rows="2"
                                                    max-rows="2"
                                                />
                                            </b-form-group>
                                            <b-button
                                                pill
                                                size="sm"
                                                variant="outline-secondary"
                                                @click="onEventStoryUp(index)"
                                            >
                                                Up
                                            </b-button>

                                            <b-button
                                                pill
                                                size="sm"
                                                variant="outline-secondary"
                                                @click="onEventStoryDown(index)"
                                            >
                                                Down
                                            </b-button>
                                        </b-card-body>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-container>
                </b-form-group>
            </b-col>
        </b-row>
        
        <b-row>
            <b-col>
                <div>
                    <b-button
                        size="sm"
                        variant="primary"
                        class="px-5 mr-2"
                        @click="onSave('continue')"
                    >
                        Save And Continue Later
                    </b-button>
                    <b-button
                        size="sm"
                        variant="primary"
                        class="px-5"
                        @click="onSave('save')"
                    >
                        Save
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { getEventIssue, saveEventIssue } from "api/admin/event-issues"
import Multiselect from "vue-multiselect"
import { mapGetters } from "vuex"
import { blobImage } from "api/blob-image"
import { DirectUpload } from "@rails/activestorage"
import countryRegionData from "country-region-data/data"

export default {
    components: { Multiselect },

    data () {
        const baseURL = document.querySelector("meta[name=\"base-url\"]").content
        const directUploadPath = document.querySelector("meta[name=\"direct-upload-path\"]").content
        const directUploadUrl = directUploadPath.startsWith("/")
            ? baseURL + directUploadPath.slice(1)
            : baseURL + directUploadPath
        const attributeDefinitions = [
            { name: "id", type: "integer", default: null },
            { name: "issued_on", type: "date", default: "" },
            { name: "active", type: "boolean", default: false },
            {
                name: "event_stories",
                type: "array",
                default: [
                    {
                        image_path: null,
                        image_picker: null,
                        title:"",
                        body:"",
                        published_at: null,
                        url: "",
                        location: "",
                        position:0
                    },{
                        image_path: null,
                        image_picker: null,
                        title:"",
                        body:"",
                        published_at: null,
                        url: "",
                        location: "",
                        position:1
                    },{
                        image_path: null,
                        image_picker: null,
                        title:"",
                        body:"",
                        published_at: null,
                        url: "",
                        location: "",
                        position:2
                    }
                ] 
            },
        ]
        const attributes = attributeDefinitions.reduce((hash, info) => {
            hash[info.name] = info.default
            return hash
        }, {})
        const removeAttributes = attributeDefinitions.reduce((hash, info) => {
            if(info.type == "array"){
                hash[info.name] = []
            }
            return hash
        }, {})

        return {
            directUploadUrl,
            attributeDefinitions,
            attributes,
            removeAttributes,
            record: null,
            HTMLEDITOR: "",
            isLoadingManufacturerProfiles: false,
            isLoadingVideos: false,
            // customModulesForEditor: [
            //     { alias: "imageDrop", module: ImageDrop },
            //     { alias: "imageResize", module: ImageResize }
            // ],
            // editorSettings: {
            //     modules: {
            //         imageDrop: true,
            //         imageResize: {
            //             modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
            //         }
            //     }
            // }
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    mounted () {
        this.onLoad()
    },

    watch: {
        '$route': {
           async handler(route) {
            this.onload();
           }
        }
    },

    methods: {
        onEventStoryUp (index) {
            if (index > 0) {
                let item1 = this.attributes.event_stories[index - 1]
                let item2 = this.attributes.event_stories[index]
                item1.position = index
                item2.position = index - 1
                this.attributes.event_stories.splice(index - 1, 1, item2)
                this.attributes.event_stories.splice(index, 1, item1)
            }
        },

        onEventStoryDown (index) {
            if (index < this.attributes.event_stories.length - 1) {
                let item1 = this.attributes.event_stories[index]
                let item2 = this.attributes.event_stories[index + 1]
                item1.position = index + 1
                item2.position = index
                this.attributes.event_stories.splice(index, 1, item2)
                this.attributes.event_stories.splice(index + 1, 1, item1)
            }
        },

        onUpload (index, name, fileOrFiles, collection) {
            if (Array.isArray(fileOrFiles)) {
                fileOrFiles.forEach(file => this.uploadAttachment(name, file, index, collection))
            } else {
                this.uploadAttachment(name, fileOrFiles, index, collection)
            }
        },

        onReset (index,name,collection) {
            console.log(`Resetting ${ name }`, this.$refs[`${ name }_${index}_input`])
            // this.$refs[`${ name }_input`].reset()
            collection[index][name] = null
            collection[index][`${ name }_path`] = null
            collection[index][`${ name }_picker`] = null
        },

        uploadAttachment (name, file, index, collection) {
            if (file === null) return
            const upload = new DirectUpload(file, this.directUploadUrl)
            upload.create((error, blob) => {
                if (error) {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to upload",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                } else {
                    blobImage(blob.signed_id)
                        .then(path => {
                            collection[index][name] = blob.signed_id
                            collection[index][`${ name }_path`] = path
                            collection[index][`${ name }_picker`] = null
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to upload",
                                variant: "danger",
                                autoHideDelay: 5000
                            })                    
                            this.onReset(index, name)
                        })
                }
            })
        },

        onSave (saveStatus){
            let record = {}
            record.id = this.attributes.id
            record.issued_on = this.attributes.issued_on
            record.active = this.attributes.active
            record.event_stories_attributes = []
            this.attributes.event_stories.forEach(event_story => {
                if(saveStatus === 'save' || !!event_story.image && !!event_story.url){
                    record.event_stories_attributes.push({
                        id: event_story.id || null,
                        event_issue_id: event_story.event_issue_id || null,
                        body: event_story.body,
                        image: event_story.image,
                        title: event_story.title,
                        position: event_story.position,
                        location: event_story.location,
                        url: event_story.url,
                        published_at: event_story.published_at,
                    })
                }
            })
            this.removeAttributes.event_stories.forEach(event_story => {
                record.event_stories_attributes.push({
                    id: event_story.id || null,
                    event_issue_id: event_story.event_issue_id || null,
                    body: event_story.body,
                    image: event_story.image,
                    title: event_story.title,
                    position: event_story.position,
                    location: event_story.location,
                    url: event_story.url,
                    published_at: event_story.published_at,
                    _destroy: true
                })
            })
            if(saveStatus === 'continue'){
                record.save_and_continue_later = true
            }
            this.saveRecord(record);
        },

        onLoad () {
            const id = this.$route.params.id
            if (id) {
                getEventIssue(this.adminToken, { id })
                    .then(record => {
                        console.log("*** Loaded")
                        console.dir(record)
                        this.record = record
                        this.attributes.id = this.record.id
                        this.attributes.issued_on = this.record.issued_on
                        this.attributes.active = this.record.active
                        // this.attributes.event_stories = this.record.news
                        this.attributes.event_stories.forEach((event_story,index)=>{
                            if(this.record.event_stories[index]){
                                this.attributes.event_stories[index] = this.record.event_stories[index]
                                if(this.record.event_stories[index].position == 0 || !this.record.event_stories[index].position) {
                                    this.attributes.event_stories[index].position = index
                                }
                            }else{
                                this.attributes.event_stories[index] = { image_path: null, image_picker: null, body:"", published_at: null, url: "", location: "", title:"", position: index }
                            }
                        })
                    })
                    .catch(error => {
                        this.$bvToast.toast(error.message, {
                            title: "Unexpected Error",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    })
            }
        },

        saveRecord (record) {
            saveEventIssue(this.adminToken, record)
                .then(newRecord => {
                    const isNewRecord = !record.id
                    this.record = newRecord
                    if (isNewRecord) {
                        this.$router.push({
                            name: "admin-edit-event-issue",
                            params: { id: newRecord.id }
                        })
                    } else {
                        this.$bvToast.toast("Succeeded!", {
                            title: "Save status",
                            variant: "success",
                            autoHideDelay: 5000
                        })
                    }
                })
                .catch(error => {
                    const h = this.$createElement
                    let elements = []
                    error.message.split(', ').forEach(_op_ =>{
                        elements.push(h('strong', _op_));
                        elements.push(h('br'));
                    })
                    let display = h('p',
                      { class: ['text-center', 'mb-0'] },
                      [
                        elements
                      ]
                    )
                    this.$bvToast.toast(display, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 10000
                    })
                })
        },
    }
}
</script>
