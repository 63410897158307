<template>
    <div class="content">
        <edit-columns
            :definitions="attributeDefinitions"
            :record="record"
            @save="onSave"
            title="Csi Division"
            :enableTitle="true"
        />
    </div>
</template>

<script>
import { getCsiIndexEntry, saveCsiIndexEntry } from "api/admin/csi-index-entries"
import { mapGetters } from "vuex"
import EditColumns from "../shared/edit-columns"
import { entryTypeOptions } from "enums/entry-types"

export default {
    components: { EditColumns },

    data () {
        const attributeDefinitions = [
            { name: "parent_id", type: "csi_index_entry", default: undefined },
            { name: "entry_type", type: "options", default: "", options: entryTypeOptions },
            { name: "code", type: "text", default: "" },
            { name: "title", type: "text", default: "" },
            { name: "excluded", type: "boolean", default: false },
        ]

        return {
            attributeDefinitions,
            record: null,
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    mounted () {
        this.onLoad()
    },

    methods: {
        onLoad () {
            const id = this.$route.params.id
            if (id) {
                getCsiIndexEntry(this.adminToken, { id })
                    .then(record => {
                        console.log("*** Loaded")
                        console.dir(record)
                        this.record = record
                    })
                    .catch(error => {
                        this.$bvToast.toast(error.message, {
                            title: "Unexpected Error",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    })
            }
        },

        onSave (record) {
            saveCsiIndexEntry(this.adminToken, record)
                .then(newRecord => {
                    const isNewRecord = !record.id
                    this.record = newRecord
                    if (isNewRecord) {
                        this.$router.push({
                            name: "admin-edit-csi-index-entry",
                            params: { id: newRecord.id }
                        })
                    } else {
                        this.$bvToast.toast("Succeeded!", {
                            title: "Save status",
                            variant: "success",
                            autoHideDelay: 5000
                        })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },
    }
}
</script>
