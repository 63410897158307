import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getNewsEntries = (token, { page = 1, sortBy = "", sortDesc = false, query } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/news_entries", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getNewsEntry = (token, { id }) => {
    return getRequest(`/admin/news_entries/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveNewsEntry = (token, newsEntry) => {
    if (newsEntry.id) {
        let { id, ...newsEntryWithoutId } = newsEntry
        return patchRequest(`/admin/news_entries/${ id }`, {
            news_entry: newsEntryWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/news_entries", {
            news_entry: newsEntry
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteNewsEntry = (token, newsEntry) => {
    return deleteRequest(`/admin/news_entries/${ newsEntry.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
