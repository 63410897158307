<template>
    <div class="content">
        <div class="title">
            <h1>Featured Firms ({{totalItems}})</h1>
        </div>
        
        <div class="content-tabs">
            <b-row>
                <b-col md="8">
                    <b-button-toolbar
                        aria-label="Actions"
                        key-nav
                        class="mb-3"
                    >
                        <b-button-group class="mx-1">
                            <b-button
                                :to="{ name: 'admin-new-featured-firm' }"
                            >
                                New Featured Firm
                            </b-button>

                            <b-button
                                class="ml-2"
                                variant="success"
                                :disabled="!isSomeChecked"
                                @click.prevent="onShowAll"
                            >
                                Save
                            </b-button>

    <!--                         <b-button
                                class="ml-2"
                                variant="danger"
                                :disabled="!isSomeChecked"
                                @click.prevent="onHideAll"
                            >
                                Hide All (selected)
                            </b-button> -->
                        </b-button-group>
                    </b-button-toolbar>
                </b-col>
            </b-row>

            <b-table
                id="index-table"
                :fields="fields"
                :items="items"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-debounce="1000"
                table-class=""
                primary-key="id"
                stacked="md"
                show-empty
                small
                striped
                hover
                responsive
                @row-clicked="onClickRow"
            >
                <template #head(check)>
                    <b-form-checkbox
                        :checked="isEveryChecked"
                        :indeterminate="indeterminateCheckAll"
                        @change="onClickedCheckAll"
                    />
                </template>

                <template #cell(check)="row">
                    <b-form-checkbox
                        v-model="row.item.active"
                    />
                </template>

                <template #cell(count)="row">
                    {{ (row.index + 1) + ($route.query.page ? ($route.query.page - 1) * itemsPerPage : 0 ) }}
                </template>

                <template #cell(firm_number)="row">
                    {{ row.item.firm_profile.id }}
                </template>

                <template #cell(tier)="row">
                    {{ row.item.firm_profile.tier }}
                </template>

                <template #cell(active)="row">
                    {{ row.item.active ? 'Yes' : 'No' }}
                </template>

                <template #cell(company_name)="row">
                    {{ row.item.firm_profile.company_name }}
                </template>

                <template #cell(state)="row">
                    {{ row.item.firm_profile.state }}
                </template>

                <template #cell(country)="row">
                    {{ row.item.firm_profile.country }}
                </template>

                <template #cell(primary_service)="row">
                    {{ row.item.firm_profile.primary_service }}
                </template>

                <template #cell(created_at)="row">
                    {{ row.item.created_at | date }}
                </template>

                <template #cell(updated_at)="row">
                    {{ row.item.updated_at | date }}
                </template>

                <template #cell(end_date)="row">
                    {{ row.item.end_date | date }}
                </template>
            </b-table>
        </div> 
    </div>
</template>

<script>
import { ApiError } from "api/base"
import { bulkActionFeaturedFirm, getFeaturedFirms } from "api/admin/featured-firms"
import { mapActions, mapGetters } from "vuex"
import AdminPaginationMixin from "../../../mixins/admin_pagination.js"

export default {
    mixins: [AdminPaginationMixin],
    data () {
        return {
            paginationDisable: false,
            items: [],
            totalItems: 0,
            itemsPerPage: 100,
            sortBy: "",
            sortDesc: false,
            fields: [
                { key: "check", sortable: false },
                // { key: "count", label: "#", sortable: true, class: "column-left" },
                { key: "id", label: "ID", sortable: true, class: "text-nowrap column-left" },
                { key: "firm_number", label: "Firm Number", sortable: true, class: "text-nowrap column-left" },
                { key: "tier", label: "Tier", sortable: true, class: "column-center" },
                { key: "company_name", label: "Company Name", sortable: false, class: "text-nowrap column-left" },
                { key: "primary_service", label: "Service", sortable: true, class: "text-nowrap column-left" },
                { key: "state", label: "State", sortable: true, class: "text-nowrap column-left" },
                { key: "country", label: "Country", sortable: true, class: "text-nowrap column-left" },
                // { key: "active", label: "Active", sortable: true, class: "text-nowrap column-left" },
                { key: "created_at", label: "Created", sortable: true, class: "text-nowrap column-center" },
                { key: "updated_at", label: "Updated", sortable: true, class: "text-nowrap column-center" },
                { key: "end_date", label: "End Date", sortable: true, class: "text-nowrap column-center" }
            ],
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),

        isSomeChecked () {
            return this.items.some(record => record.active)
        },

        isEveryChecked () {
            return this.items.every(record => record.active)
        },

        indeterminateCheckAll () {
            return !this.isEveryChecked && this.isSomeChecked
        }
    },

    watch: {
        "$route": "loadFeaturedFirms",

        sortBy (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortBy: newValue,
                sortDesc: this.sortDesc
            })
            this.$router.push({ name: this.$route.name, query })
        },

        sortDesc (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortDesc: newValue,
                sortBy: this.sortBy
            })
            this.$router.push({ name: this.$route.name, query })
        },
    },

    mounted () {
        this.loadFeaturedFirms()
    },

    methods: {
        ...mapActions("account", ["adminSignout"]),

        onClickedCheckAll (isChecked) {
            this.items.forEach(record => record.active = isChecked)
        },

        onShowAll() {
            bulkActionFeaturedFirm(this.adminToken, 'showAll', this.items.filter(i => i.active).map(x => x.id))
                .then(() => {
                    this.loadFeaturedFirms()
                    this.$bvToast.toast("Succeeded!", {
                        title: "Saved.",
                        variant: "success",
                        autoHideDelay: 5000
                    })
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        this.$bvToast.toast(error.message, {
                            title: "Unexpected Error",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    }
                })
        },

        onHideAll() {
            bulkActionFeaturedFirm(this.adminToken, 'hideAll', this.items.filter(i => i.active).map(x => x.id))
            .then(() => {
                this.loadFeaturedFirms()
                this.$bvToast.toast("Succeeded!", {
                    title: "Saved.",
                    variant: "success",
                    autoHideDelay: 5000
                })
            })
            .catch(error => {
                if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                    this.adminSignout(null)
                    this.$router.go()
                } else {
                    this.$bvToast.toast(error.message, {
                        title: "Unexpected Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                }
            })

        },


        onSearch () {
            this.loadFeaturedFirms();
        },
        
        linkGen () {
            return {
                path: this.$route.path,
                query: Object.assign({}, this.$route.query)
            }
        },

        loadFeaturedFirms () {
            // this.page = this.$route.query.page || 1
            this.sortBy = this.$route.query.sortBy || ""
            this.sortDesc = !!this.$route.query.sortDesc
            this.paginationDisable = true;
            getFeaturedFirms(this.adminToken, {
                page: this.currentPage,
                per_page: this.itemsPerPage,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                query: this.$route.query.query
            })
                .then(result => {
                    this.currentPage = result.meta.page
                    this.totalItems = result.meta.total_count
                    this.lastPage = result.meta.total_pages
                    this.items.splice(0, this.items.length, ...result.data)
                    this.paginationDisable = false;
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        this.$bvToast.toast(error.message, {
                            title: "Unexpected Error",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    }
                })
        },

        onClickRow (item) {
            this.$router.push({
                name: "admin-edit-featured-firm",
                params: { id: item.id }
            })
        },
    }
}
</script>

<style lang="scss">
#index-table {
    th {
        white-space: nowrap !important;

        div {
            white-space: nowrap !important;            
        }
    }
}
.title {     
    padding-top: 1rem;
    width: -webkit-fill-available;
    position: fixed;
    background-color: #fff;
    z-index: 20;
}

.content-tabs { 
    position: relative;
    top: 70px;
}
</style>