<template>
    <div class="content">
        <div class="title">
            <h1>Design Firms</h1>
        </div>
        
        <div class="content-tabs">
            <b-tabs
                v-model="currentTab"
                content-class="mt-3"
                active-nav-item-class="font-weight-bold text-uppercase text-light bg-primary"
                active-tab-class="font-weight-bold text-success"
            >
                <b-tab :title="allTab" :active="currentTab <= 0" :disabled="paginationDisable && currentTab > 0"/>
                <b-tab :title="applicationsTab" :active="currentTab === 1" :disabled="paginationDisable && currentTab != 1"/>
                <b-tab :title="paymentPendingTab" :active="currentTab === 3" :disabled="paginationDisable && currentTab != 2"/>
                <b-tab :title="preApprovedTab" :active="currentTab === 2" :disabled="paginationDisable && currentTab != 3"/>
                <b-tab :title="submittedTab" :active="currentTab === 4" :disabled="paginationDisable && currentTab != 4"/>
                <b-tab :title="publishedTab" :active="currentTab === 5" :disabled="paginationDisable && currentTab != 5"/>
                <b-tab :title="deniedTab" :active="currentTab === 6" :disabled="paginationDisable && currentTab != 6"/>
                <b-tab :title="claimedTab" :active="currentTab === 7" :disabled="paginationDisable && currentTab != 7"/>
                <b-tab :title="unClaimedTab" :active="currentTab === 8" :disabled="paginationDisable && currentTab != 8"/>
            </b-tabs>

            <b-row>
                <b-col>
                    <b-button-toolbar
                        v-if="currentTab <= 0"
                        aria-label="Actions"
                        key-nav
                        class="mb-3"
                    >
                        <b-button-group class="mx-1">
                            <b-button
                                :to="{ name: 'admin-new-firm' }"
                            >
                                New Firm Profile
                            </b-button>
                        </b-button-group>
                    </b-button-toolbar>
                </b-col>
                <b-col class="d-flex justify-content-end">
                    <b-pagination-nav
                        v-model="currentPage"
                        :number-of-pages="lastPage"
                        :limit="7"
                        :to="{ name: 'admin-firms' }"
                        :disabled="paginationDisable"
                        @page-click="onPageClick"
                    />
                </b-col>
            </b-row>

            <b-table
                id="index-table"
                :fields="fields"
                :items="items"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                primary-key="id"
                stacked="md"
                show-empty
                small
                striped
                hover
                @row-clicked="onClickRow"
                :busy.sync="paginationDisable"
            >
                <template #table-busy>
                    <div class="text-center my-2" style="color: #343a40;">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template #cell(count)="row">
                    {{ (row.index + 1) + (currentPage ? (currentPage - 1) * itemsPerPage : 0 ) }}
                </template>

                <template #cell(claimed)="row">
                    {{ row.item.claimed ? "Yes" : "No" }}
                </template>

                <template #cell(company_name)="row">
                    <router-link
                        :to="{ name: 'firm-profile', params: { id: row.item.id } }"
                        target="_blank"
                    >
                        {{ row.item.company_name }}
                    </router-link>
                </template>

                <template #cell(site)="row">
                    <a
                        :href="row.item.site | url"
                        target="_blank"
                    >
                        {{ row.item.site }}
                    </a>
                </template>

                <template #cell(tier)="row">
                    {{ row.item.tier }}
                </template>

                <template #cell(created_at)="row">
                    {{ row.item.created_at | date }}
                </template>

                <template #cell(action_date)="row">
                    {{ getActionDate(row) | date }}
                </template>

    <!--             <template #cell(country)="row">
                    {{ row.item.country | country }}
                </template>

                <template #cell(state)="row">
                    {{ row.item.state | region(row.item.country) }}
                </template> -->

                <template #cell(approved_at)="row">
                    {{ row.item.approved_at | date }}
                </template>

                <template #cell(live_at)="row">
                    {{ row.item.live_at | date }}
                </template>
    <!--              <template #cell(created_at)="row">
                    {{ row.item.created_at | date }}
                </template>

                <template #cell(updated_at)="row">
                    {{ row.item.updated_at | date }}
                </template> -->

                <template #cell(approval)="row">
                    <b-button
                        size="sm"
                        variant="success"
                        @click.prevent="onApprove(row.item)"
                        class="approve"
                    >
                        Approve
                    </b-button>

                    <b-button
                        size="sm"
                        variant="danger"
                        @click.prevent="onReportProfile(row.item.id)"
                        class="deny"
                    >
                        Deny
                    </b-button>

                    <b-button
                        size="sm"
                        variant="danger"
                        @click.prevent="onDelete(row.item)"
                        v-if="approvalStates[currentTab] != 'submitted'"
                        class="delete-button"
                    >
                        Delete
                    </b-button>

                    <b-button
                        size="sm"
                        variant="warning"
                        @click.prevent="onCommentProfile(row.item.id)"
                        v-if="approvalStates[currentTab] == 'submitted'"
                        class="comment"
                    >
                        Comment
                    </b-button>
                </template>
            </b-table>

            <div class="d-flex justify-content-end">
                <b-pagination-nav
                    v-model="currentPage"
                    :number-of-pages="lastPage"
                    :limit="7"
                    :to="{ name: 'admin-firms' }"
                    :disabled="paginationDisable"
                    @page-click="onPageClick"
                />
            </div>

            <b-modal
                id="denied_reason_modal"
                title="Denial Reasons"
                size="lg"
            >
                <b-form @submit.stop.prevent="onSubmitNewReport">
                    <b-form-group label="Select report topic:" v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group
                        id="checkbox-group-1"
                        v-model="newReport.topics"
                        :options="[{
                            text: 'Your Firm is not located in USA or Canada', value: 'Your Firm is not located in USA or Canada', disabled: !!newReport.id
                        },{
                            text: 'Your Firm does not provide dedicated professional design services *', value: 'Your Firm does not provide dedicated professional design services *', disabled: !!newReport.id
                        },{
                            text: 'Your Website does not meet our standards', value: 'Your Website does not meet our standards', disabled: !!newReport.id
                        }]"
                        :aria-describedby="ariaDescribedby"
                        name="flavour-1"
                        stacked
                      ></b-form-checkbox-group>
                    </b-form-group>
                </b-form>

                <template #modal-footer>
                    <b-button
                        variant="primary"
                        @click="onSubmitNewReport"
                        :disabled="!!newReport.id"
                    >
                        Submit report
                    </b-button>

                    <b-button
                        variant="secondary"
                        @click="$bvModal.hide('denied_reason_modal')"
                    >
                        Cancel
                    </b-button>
                </template>
            </b-modal>

            <b-modal
                id="comment_profile_modal"
                title="Add Comment"
                size="lg"
            >
                <b-form @submit.stop.prevent="onSubmitNewComment">
                    <b-form-group
                        label="Comment Box:"
                        label-for="new_review_body"
                        :state="newReport.bodyState"
                    >
                        <b-form-textarea
                            v-model="newReport.body"
                            type="text"
                            placeholder="Write your comment..."
                            rows="6"
                            max-rows="6"
                            :disabled="!!newReport.id"
                        />
                    </b-form-group>
                </b-form>

                <template #modal-footer>
                    <b-button
                        variant="primary"
                        @click="onSubmitNewComment"
                        :disabled="!!newReport.id"
                    >
                        Submit comment
                    </b-button>

                    <b-button
                        variant="secondary"
                        @click="$bvModal.hide('comment_profile_modal')"
                    >
                        Cancel
                    </b-button>
                </template>
            </b-modal>
    <!--         <transition name="fade">
                <b-button id="pagetop" style="position: fixed; right: 30px; bottom: 30px;" v-show="scY > getPageHeight()" @click="toTop">
                  Back To Top
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 28" fill="none"
                       stroke="#ffffff"
                       stroke-width="3" stroke-linecap="square" stroke-linejoin="arcs">
                    <path d="M18 15l-6-6-6 6"/>
                  </svg>
                </b-button>
              </transition> -->
              <back-to-top/>
        </div>
    </div>
</template>

<script>
import { getFirms, saveFirm, deleteFirm, approvalStates } from "api/admin/firms"
import { mapGetters } from "vuex"
import AdminPaginationMixin from "../../../mixins/admin_pagination.js"
import { getReports, saveReport } from "api/admin/reports"
import BackToTop from "components/shared/back-to-top"
// import BackToTopMixin from "mixins/back_to_top.js"

export default {
    components: {
        BackToTop
    },
    mixins: [AdminPaginationMixin],
        // , BackToTopMixin],
    data () {
        const approveStates = [undefined, "application", "payment-pending", "pre-approved", "submitted", "published", "denied", "claimed", "unclaimed"]
        const sortedItems = approveStates.reduce((acc, state) => {
            acc[state || 'all'] = [];
            return acc;
        }, {});
        return {
            totalPages: 1,
            paginationDisable: false,
            currentTab: 0,
            sortedItems: sortedItems,
            items: [],
            totalItems: 0,
            itemsPerPage: 500,
            sortBy: "",
            sortDesc: false,
            isLoading: false,
            applicationFields: [
                { key: "id", label: "ID", sortable: true, class: "column-left", thStyle: { width: "120px" } },
                { key: "tier", label: "Tier", sortable: true, class: "column-center" },
                { key: "created_at", label: "Created", sortable: true, class: "column-center", thStyle: { width: "100px"} },
                // { key: "action_date", label: "Action Date", sortable: true, class: "column-center", thStyle: { width: "100px" } },
                { key: "company_name", label: "Company", sortable: true, class: "column-left", thStyle: { width: "400px" } },
                { key: "site", label: "Web", sortable: false, class: "column-right", thStyle: { width: "200px"} },
                { key: "owner_name", label: "Administrator", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                { key: "owner_email", label: "Administrator Email", sortable: false, class: "column-right", thStyle: { width: "200px" } },
                { key: "owner_phone", label: "Phone Number", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                { key: "country", label: "Country", sortable: false, class: "column-right", thStyle: { width: "50px"} },
                { key: "state", label: "ST/PR", sortable: false, class: "column-right", thStyle: { width: "50px"} },
                { key: "primary_service", label: "Service", sortable: true, class: "column-left", thStyle: { width: "150px" } },
                { key: "live_at", label: "Live Date", sortable: true, class: "column-center", thStyle: { width: "100px" }  },
                { key: "approval", label: "Actions", sortable: false, class: "column-center", thStyle: { width: "400px"} },
            ],
            allFields: [
                { key: "count", label: "#", sortable: true, class: "column-left", thStyle: { width: "100px" } },
                
                { key: "id", label: "ID", sortable: true, class: "column-left", thStyle: { width: "100px" } },
                { key: "tier", label: "Tier", sortable: true, class: "column-center" },
                { key: "primary_service", label: "Service", sortable: true, class: "column-left", thStyle: { width: "150px" } },
                { key: "claimed", label: "Claimed", sortable: true, class: "column-left", thStyle: { width: "100px" } },
                { key: "company_name", label: "Company", sortable: true, class: "column-left", thStyle: { width: "400px" } },
                { key: "phone", label: "Phone", sortable: false, class: "column-right", thStyle: { width: "150px" } },
                // { key: "country", label: "Country", sortable: true, class: "column-center" },
                { key: "state", label: "ST/PR", sortable: true, class: "column-center", thStyle: { width: "100px" } },
                { key: "profile_state", label: "Status", sortable: true, class: "column-center", thStyle: { width: "200px" } },
                { key: "approved_at", label: "Approve", sortable: true, class: "column-center" },
                { key: "live_at", label: "Live", sortable: true, class: "column-center" },
                { key: "tier", label: "Tier", sortable: true, class: "column-right", thStyle: { width: "50px" }  },
                // { key: "created_at", label: "Created", sortable: true, class: "column-center" },
                // { key: "updated_at", label: "Updated", sortable: true, class: "column-center" }
            ],
            approvalFields: [
                { key: "id", label: "ID", sortable: true, class: "column-left", thStyle: { width: "120px" } },
                { key: "tier", label: "Tier", sortable: true, class: "column-center" },
                { key: "created_at", label: "Created", sortable: true, class: "column-center", thStyle: { width: "100px"} },
                // { key: "action_date", label: "Action Date", sortable: true, class: "column-center", thStyle: { width: "100px" } },
                { key: "company_name", label: "Company", sortable: true, class: "column-left", thStyle: { width: "400px" } },
                { key: "site", label: "Web", sortable: false, class: "column-right", thStyle: { width: "200px"} },
                { key: "owner_name", label: "Administrator", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                { key: "owner_email", label: "Administrator Email", sortable: false, class: "column-right", thStyle: { width: "200px" } },
                { key: "owner_phone", label: "Phone Number", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                { key: "country", label: "Country", sortable: false, class: "column-right", thStyle: { width: "50px"} },
                { key: "state", label: "ST/PR", sortable: false, class: "column-right", thStyle: { width: "50px"} },
                { key: "primary_service", label: "Service", sortable: true, class: "column-left", thStyle: { width: "150px" } },
                // { key: "approved_at", label: "Approved At", sortable: true, class: "column-center", thStyle: { width: "100px" }  },
                // { key: "live_at", label: "Live Date", sortable: true, class: "column-center", thStyle: { width: "100px" }  },
                { key: "approval", label: "Actions", sortable: false, class: "column-center", thStyle: { width: "400px"} },
            ],
            approvalFieldsWithoutActions: [
                { key: "id", label: "ID", sortable: true, class: "column-left", thStyle: { width: "120px" } },
                { key: "tier", label: "Tier", sortable: true, class: "column-center" },
                { key: "created_at", label: "Created", sortable: true, class: "column-center", thStyle: { width: "100px"} },
                // { key: "action_date", label: "Action Date", sortable: true, class: "column-center", thStyle: { width: "100px" } },
                { key: "company_name", label: "Company", sortable: true, class: "column-left", thStyle: { width: "400px" } },
                { key: "site", label: "Web", sortable: false, class: "column-right", thStyle: { width: "200px"} },
                { key: "owner_name", label: "Administrator", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                { key: "owner_email", label: "Administrator Email", sortable: false, class: "column-right", thStyle: { width: "200px" } },
                { key: "owner_phone", label: "Phone Number", sortable: false, class: "column-right", thStyle: { width: "100px" } },
                { key: "country", label: "Country", sortable: false, class: "column-right", thStyle: { width: "50px"} },
                { key: "state", label: "ST/PR", sortable: false, class: "column-right", thStyle: { width: "50px"} },
                { key: "primary_service", label: "Service", sortable: true, class: "column-left", thStyle: { width: "150px" } },
                // { key: "approved_at", label: "Approved At", sortable: true, class: "column-center", thStyle: { width: "100px" }  },
                // { key: "live_at", label: "Live Date", sortable: true, class: "column-center", thStyle: { width: "100px" }  },
                // { key: "approval", label: "Actions", sortable: false, class: "column-center", thStyle: { width: "400px"} },
            ],
            approvalStates: approveStates,
            sortingSetup: {
                undefined: {sort_by: 'created_at', sort_desc: true},
                application: {sort_by: 'created_at', sort_desc: true},
                'pre-approved': {sort_by: 'created_at', sort_desc: true},
                'payment-pending': {sort_by: 'created_at', sort_desc: true},
                submitted: {sort_by: 'created_at', sort_desc: true},
                published: {sort_by: 'created_at', sort_desc: true},
                denied: {sort_by: 'created_at', sort_desc: true},
                claimed: {sort_by: 'created_at', sort_desc: true},
                unclaimed: {sort_by: 'created_at', sort_desc: true}
            },
            approvalStateCounter: {
                all: 0,
                application: 0,
                preApproved: 0,
                paymentPending: 0,
                submitted: 0,
                published: 0,
                denied: 0,
                claimedProfiles: 0,
                unclaimedProfiles: 0,
            },
            newReport:{
                body: null,
                bodyState: null,
                topics: [],
                report_type: 'initial'
            },
            selectedFirm: null
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),

        fields () {
            console.log('Current tab in fields', this.currentTab)
            if(this.currentTab <= 0){
                return this.approvalFieldsWithoutActions
            }
            else if(this.currentTab == 1){
                return this.applicationFields
            }else if(this.currentTab === 0 || this.currentTab === 7){
                return this.allFields
            }else if([2,3,5].includes(this.currentTab)){
                return this.approvalFieldsWithoutActions
            }else{
                return this.approvalFields
            }
        },

        items: {
            get() {
                if(this.currentTab <= 0){
                    return this.sortedItems['all'];
                }else{
                    return this.sortedItems[this.approvalStates[this.currentTab]];
                }
            },
            set(newValue) {
                if(this.currentTab <= 0){
                    this.sortedItems['all'] = newValue
                }else{
                    this.sortedItems[this.approvalStates[this.currentTab]] = newValue
                }
            }
        },

        allTab () {
            return `All (${this.approvalStateCounter.all})`
        },

        applicationsTab () {
            return `Applications (${this.approvalStateCounter.application})`
        },

        preApprovedTab () {
            return `Pre-approved (${this.approvalStateCounter.preApproved})`
        },

        paymentPendingTab () {
            return `Payment Pending (${this.approvalStateCounter.paymentPending})`
        },

        submittedTab () {
            return `Submitted (${this.approvalStateCounter.submitted})`
        },

        publishedTab () {
            return `Published (${this.approvalStateCounter.published})`
        },

        deniedTab () {
            return `Denied (${this.approvalStateCounter.denied})`
        },

        claimedTab () {
            return `Claimed (${this.approvalStateCounter.claimedProfiles})`
        },

        unClaimedTab () {
            return `Unclaimed (${this.approvalStateCounter.unclaimedProfiles})`
        },
    },

    watch: {
        ["$route"] () {
            this.loadFirmProfiles()
        },

        sortBy (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortBy: newValue,
                sortDesc: this.sortDesc
            })
            this.$router.push({ name: "admin-firms", query })
        },

        sortDesc (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortDesc: newValue,
                sortBy: this.sortBy
            })
            this.$router.push({ name: "admin-firms", query })
        },

        currentTab () {
            this.loadFirmProfiles(1)
        },
    },

    mounted () {
        this.loadFirmProfiles();
        window.addEventListener('scroll', this.handleScroll);
    },

    methods: {

        getActionDate (row) {
            switch(this.currentTab){
            case 1:
                return row.item.created_at
                break;
            case 2:
                return row.item.pre_approved_action_date
                break;
            case 3:
                return row.item.submitted_action_date
                break;
            case 4:
                return row.item.payment_pending_action_date
                break;
            case 5:
                return row.item.published_action_date
                break;
            case 6:
                return row.item.denied_action_date
                break;
            case 7:
                return row.item.claimed_action_date
                break;
            case 8:
                return row.item.updated_at
                break;
            default:

            }
        },
        
        onSubmitNewComment () {
            console.log({
                reportable_type: "FirmProfile",
                reportable_id: this.selectedFirm,
                body: this.newReport.body,
                topics: this.newReport.topics.join(', '),
                report_type: 'commented'
            });
            saveReport(this.adminToken ,{
                reportable_type: "FirmProfile",
                reportable_id: this.selectedFirm,
                body: this.newReport.body,
                topics: this.newReport.topics.join(', '),
                report_type: 'commented'
            }).then(result => {
                this.$bvModal.hide("comment_profile_modal");
                this.onRevert(this.selectedFirm);
            }).catch(error => this.reportError("Unable to post report", error))
        },

        onSubmitNewReport () {
            console.log({
                reportable_type: "FirmProfile",
                reportable_id: this.selectedFirm,
                body: this.newReport.body,
                topics: this.newReport.topics.join(', '),
                report_type: 'denied'
            });
            saveReport(this.adminToken ,{
                reportable_type: "FirmProfile",
                reportable_id: this.selectedFirm,
                body: this.newReport.body,
                topics: this.newReport.topics.join(', '),
                report_type: 'denied'
            }).then(result => {
                this.$bvModal.hide("denied_reason_modal");
                this.onDeny(this.selectedFirm);
            }).catch(error => this.reportError("Unable to post report", error))
        },
        
        onReportProfile (id) {
            this.selectedFirm = id;
            this.newReport.body = null
            this.newReport.bodyState = null
            this.newReport.topics = []
            this.newReport.id = null
            this.newReport.report_type = 'initial'
            this.$bvModal.show("denied_reason_modal")
        },
        
        onCommentProfile (id) {
            this.selectedFirm = id;
            this.newReport.body = null
            this.newReport.bodyState = null
            this.newReport.topics = []
            this.newReport.id = null
            this.newReport.report_type = 'initial'
            this.$bvModal.show("comment_profile_modal")
        },

        loadApprovalStates () {
            approvalStates(this.adminToken)
                .then(result => {
                    this.approvalStateCounter.all = result.all
                    this.approvalStateCounter.application = result.application
                    this.approvalStateCounter.preApproved = result.pre_approved
                    this.approvalStateCounter.paymentPending = result.payment_pending
                    this.approvalStateCounter.submitted = result.submitted
                    this.approvalStateCounter.published = result.published
                    this.approvalStateCounter.denied = result.denied
                    this.approvalStateCounter.claimedProfiles = result.claimed_profiles
                    this.approvalStateCounter.unclaimedProfiles = result.unclaimed_profiles
                })
        },

        onSearch () {
            this.loadFirmProfiles();
        },
        
        linkGen () {
            return {
                path: this.$route.path,
                query: Object.assign({}, this.$route.query)
            }
        },

        loadFirmProfiles (page = null) {
            this.isLoading = true;
            this.firms = [];
            this.sortBy = this.$route.query.sortBy
            this.sortDesc = this.$route.query.sortDesc
            let {sort_by, sort_desc} = this.sortingSetup[this.approvalStates[this.currentTab]];
            this.paginationDisable = true;
            getFirms(this.adminToken, {
                page: this.currentPage,
                per_page: this.itemsPerPage,
                sortBy: this.$route.query.sortBy ? this.sortBy : sort_by,
                sortDesc: this.$route.query.sortDesc ? this.sortDesc : sort_desc,
                approvalState: this.approvalStates[this.currentTab],
                query: this.$route.query.query
            })
                .then(result => {
                    this.currentPage = result.meta.page
                    this.lastPage = result.meta.total_pages
                    this.totalItems = result.meta.total_count
                    this.totalPages = result.meta.total_pages
                    this.itemsPerPage = result.meta.per_page
                    this.items.splice(0, this.items.length, ...result.data)
                    // console.log("Result", result)
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unexpected Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error.message)
                }).finally(() => {
                    this.paginationDisable = false;
                    this.isLoading = false;
                })
            this.loadApprovalStates()
        },

        onClickRow (item) {
            this.$router.push({
                name: "admin-edit-firm",
                params: { id: item.id }
            })
        },


        onApprove (item) {
            const applicationWithBasic = (this.currentTab === 1 && item.profile_state == 'application' && (item.plan === 'basic' || item.plan === null))
            const applicationWithoutBasic = (this.currentTab === 1 && item.profile_state == 'application' && item.plan !== 'basic')
            const preApprovedWithoutBasic = this.currentTab === 3
            const submittedWithoutBasic = this.currentTab === 4

            const stage = applicationWithBasic ? "pre-approved" : applicationWithoutBasic ? 'payment-pending' : preApprovedWithoutBasic ? "submitted" : submittedWithoutBasic ? "published" : 'submitted'
            
            saveFirm(this.adminToken, {
                id: item.id,
                profile_state: `${ stage }`
            })
                .then(() => {
                    this.loadFirmProfiles()
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },
        
        onRevert (id) {
            // const stage = this.currentTab === 1 ? "first" : this.currentTab === 2 ? "second" : "third"
            const stage = this.currentTab === 1 ? "first-saved" : this.currentTab === 2 ? "application" : "pre-approved"
            // const stage = 'denied'
            saveFirm(this.adminToken, {
                id,
                profile_state: `${ stage }`
            })
                .then(() => {
                    
                    this.$bvToast.toast("Comment Succeeded.", {
                                    title: "Success",
                                    variant: "success",
                                    autoHideDelay: 5000
                                })
                    this.loadFirmProfiles()
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onDeny (id) {
            // const stage = this.currentTab === 1 ? "first" : this.currentTab === 2 ? "second" : "third"
            // const stage = this.currentTab === 1 ? "first-saved" : this.currentTab === 2 ? "application" : "pre-approved"
            const stage = 'denied'
            saveFirm(this.adminToken, {
                id,
                profile_state: `${ stage }`
            })
                .then(() => {
                    this.$bvToast.toast("Denied Succeeded.", {
                                    title: "Success",
                                    variant: "success",
                                    autoHideDelay: 5000
                                })
                    this.loadFirmProfiles()
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onDelete (item) {
            const message = `Are you sure you want to delete “${ item.company_name }”? This firm will be deleted immediately. You can't undo this action.`

            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
                .then(isConfirmed => {
                    if (isConfirmed) {
                        deleteFirm(this.adminToken, item)
                            .then(() => {
                                // this.$bvToast.toast("Firm deleted.", {
                                //     title: "Success",
                                //     variant: "success",
                                //     autoHideDelay: 5000
                                // })
                                this.$nextTick(() => {
                                    // this.$router.push({ name: "admin-firms" })
                                    this.loadFirmProfiles();
                                })
                            })
                            .catch(error => {
                                this.$bvToast.toast(error.message, {
                                    title: "Unexpected Error",
                                    variant: "danger",
                                    autoHideDelay: 5000
                                })
                            })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unknown error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.approve {
    background-color: transparent;
    color: #28a745;
    border: 2px solid #28a745;
}
.comment {
    background-color: transparent;
    color: #ffc107;
    border: 2px solid #ffc107;
}
.deny, .delete-button  {
    background-color: transparent;
    color: #dc3545;
    border: 2px solid #dc3545;
}
.title {     
    padding-top: 1rem;
    width: -webkit-fill-available;
    position: fixed;
    background-color: #fff;
    z-index: 20;
}

.content-tabs { 
    position: relative;
    top: 70px;
}
</style>
