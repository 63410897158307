<template>
    <div class="row flex-xl-nowrap2">
        <div class="bd-sidebar border-bottom-0 col-md-3 col-xl-2 col-12 text-white bg-dark">
            <b-nav vertical role="tablist" class="accordion">
                <b-nav-item v-b-toggle.homepagesubmenu role="tab">
                    Homepage
                    <b-icon icon="caret-down-fill" font-scale="0.8" />
                </b-nav-item>
                <b-collapse :visible="$route.name == 'admin-heroes' || $route.name == 'admin-banners' || $route.name == 'admin-building-products' || $route.name == 'admin-design-professionals'" id="homepagesubmenu" aria-expanded="false" role="tabpanel" accordion="my-accordion">
                    <b-nav-item
                        :to="{ name: 'admin-heroes' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Heroes
                    </b-nav-item>

                    <b-nav-item
                        :to="{ name: 'admin-banners' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Banners
                    </b-nav-item>
                    
                    <b-nav-item
                        :to="{ name: 'admin-building-products' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Building Products
                    </b-nav-item>

                    <b-nav-item
                        :to="{ name: 'admin-design-professionals' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Design Professionals
                    </b-nav-item>
                </b-collapse>
                <b-nav-item v-b-toggle.featured  role="tab">
                    Featured
                    <b-icon icon="caret-down-fill" font-scale="0.8" />
                </b-nav-item>
                <b-collapse :visible="$route.name == 'admin-featured-manufacturers' || $route.name == 'admin-featured-firms'" id="featured" aria-expanded="false" class="w-100"  role="tabpanel" accordion="my-accordion">
                    <b-nav-item
                        :to="{ name: 'admin-featured-manufacturers' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Featured Manufacturers
                    </b-nav-item>

                    <b-nav-item
                        :to="{ name: 'admin-featured-firms' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Featured Firms
                    </b-nav-item>

<!--                     <b-nav-item
                        :to="{ name: 'admin-featured-videos' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Featured Videos
                    </b-nav-item> -->
                </b-collapse>

                <b-nav-item v-b-toggle.manufacturersproducts role="tab">
                    Manufacturers
                    <b-icon icon="caret-down-fill" font-scale="0.8" />
                </b-nav-item>
                <b-collapse :visible="$route.name == 'admin-manufacturers' || $route.name == 'admin-csi-index-entries' || $route.name == 'admin-csi-sub-divisions' || $route.name == 'admin-product-types'" id="manufacturersproducts" aria-expanded="false" role="tabpanel" accordion="my-accordion">
                    <b-nav-item
                        :to="{ name: 'admin-manufacturers' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Manufacturers
                    </b-nav-item>

                    <b-nav-item
                        :to="{ name: 'admin-csi-index-entries' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        CSI Divisions
                    </b-nav-item>

                    <b-nav-item
                        :to="{ name: 'admin-csi-sub-divisions' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        CSI Sub Divisions
                    </b-nav-item>

                    <b-nav-item
                        :to="{ name: 'admin-product-types' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Product Types
                    </b-nav-item>
                </b-collapse>

                <b-nav-item v-b-toggle.firms role="tab">
                    Firms
                    <b-icon icon="caret-down-fill" font-scale="0.8" />
                </b-nav-item>
                <b-collapse :visible="$route.name == 'admin-firms' || $route.name == 'admin-services'" id="firms" aria-expanded="false" role="tabpanel" accordion="my-accordion">
                    <b-nav-item
                        :to="{ name: 'admin-firms' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Design Firms
                    </b-nav-item>

                    <b-nav-item
                        :to="{ name: 'admin-services' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Services
                    </b-nav-item>
                </b-collapse>

                <b-nav-item v-b-toggle.news role="tab">
                    News
                    <b-icon icon="caret-down-fill" font-scale="0.8" />
                </b-nav-item>
                <b-collapse :visible="$route.name == 'admin-news-entries' || $route.name == 'admin-industry-insider' || $route.name == 'admin-events' || $route.name == 'admin-building-product-showcase' || $route.name == 'admin-ceu-learning-centers' || $route.name == 'admin-videos'" id="news" aria-expanded="false" role="tabpanel" accordion="my-accordion">
                    <b-nav-item
                        :to="{ name: 'admin-news-entries' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        News Entries
                    </b-nav-item>
                    <b-nav-item
                        :to="{ name: 'admin-industry-insider' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Industry Insider
                    </b-nav-item>
                    <b-nav-item
                        :to="{ name: 'admin-events' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Events
                    </b-nav-item>
                    <b-nav-item
                        :to="{ name: 'admin-building-product-showcase' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Building Product Showcase
                    </b-nav-item>
                    <b-nav-item
                        :to="{ name: 'admin-ceu-learning-centers' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        CEU Learning Center
                    </b-nav-item>

                    <b-nav-item
                        :to="{ name: 'admin-videos' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Videos
                    </b-nav-item>
<!--                     <b-nav-item
                        :to="{ name: 'admin-event-stories' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Event Stories
                    </b-nav-item> -->

                </b-collapse>
                <b-nav-item
                    :to="{ name: 'admin-metrics' }"
                    exact-active-class="active"
                    class="text-nowrap"
                >
                    Metrics
                </b-nav-item>
                <b-nav-item
                    :to="{ name: 'admin-side-user-demographics' }"
                    exact-active-class="active"
                    class="text-nowrap"
                >
                    Site User Demographics
                </b-nav-item>

<!--                 <b-nav-item
                    :to="{ name: 'admin-products' }"
                    active-class="active"
                    class="text-nowrap"
                >
                    Products
                </b-nav-item> -->

                <b-nav-item v-b-toggle.accounts role="tab">
                    Accounts
                    <b-icon icon="caret-down-fill" font-scale="0.8" />
                </b-nav-item>
                <b-collapse :visible="$route.name == 'admin-admins' || $route.name == 'admin-users' || $route.name == 'admin-claim-requests' || $route.name == 'admin-newsletter-signups'" id="accounts" aria-expanded="false" role="tabpanel" accordion="my-accordion">
                    <b-nav-item
                        :to="{ name: 'admin-admins' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Admins
                    </b-nav-item>

                    <b-nav-item
                        :to="{ name: 'admin-users' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Users
                    </b-nav-item>

                    <b-nav-item
                        :to="{ name: 'admin-claim-requests' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Claim Requests
                    </b-nav-item>
                    <b-nav-item
                        :to="{ name: 'admin-newsletter-signups' }"
                        exact-active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Newsletter Signups
                    </b-nav-item>

                    <!-- <b-nav-item
                        :to="{ name: 'admin-favorites' }"
                        active-class="active"
                        class="text-nowrap"
                    >
                        <b-icon icon="chevron-right" font-scale="1" />
                        Favorites
                    </b-nav-item> -->
                </b-collapse>
            </b-nav>
        </div>

        <div class="bd-content col-md-9 col-xl-10 col-12 p-md-3-custom">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.bd-sidebar {
    order: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1)
}

.nav-link {
    color: #fff;

    &.active {
        font-weight: bold;
    }
}

@media (min-width: 768px) {
    .bd-sidebar {
        border-right: 1px solid rgba(0, 0, 0, .1)
    }

    @supports ((position: -webkit-sticky) or (position: sticky)) {
        .bd-sidebar {
            position: -webkit-sticky;
            position: sticky;
            top: 4rem;
            z-index: 1000;
            height: calc(100vh - 4rem)
        }
    }
}

@media (min-width: 1200px) {
    .bd-sidebar {
        flex:0 1 320px
    }
}

.bd-content {
    order: 1;
}

.bd-content > h2[id],
.bd-content > h3[id],
.bd-content > h4[id] {
    pointer-events: none;

    &:before {
        display: block;
        height: 6rem;
        margin-top: -6rem;
        content: ""
    }
}

.bd-content > table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
}

.p-md-3-custom {
    padding: 0rem 1rem !important;
}

@media (max-width: 991.98px) {
    .bd-content > table {
        display: block;
        overflow-x: auto;
    }

    .bd-content > table.table-bordered {
        border: 0;
    }
}
</style>
