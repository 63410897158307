<template>
    <div class="content">
        <h1>Design Firm “{{ firm.company_name }}”</h1>

        <b-button-toolbar
            aria-label="Actions"
            key-nav
            class="mb-3"
        >
            <b-button-group class="mx-1">
                <b-button
                    :to="{ name: 'admin-edit-firm', params: { id: $route.params.id } }"
                >
                    Edit Firm Profile
                </b-button>
            </b-button-group>

            <b-button-group class="mx-1">
                <b-button
                    variant="danger"
                    @click="onClickDelete"
                >
                    Delete
                </b-button>
            </b-button-group>

            <b-button-group
                v-if="isApprovalPending || canSuspend || canPublish"
                class="mx-1"
            >
                <b-button
                    v-if="isApprovalPending"
                    variant="success"
                    @click="onClickApprove"
                >
                    Approve
                </b-button>
                <b-button
                    v-if="isApprovalPending"
                    variant="danger"
                    @click="onClickDeny"
                >
                    Deny
                </b-button>
                <b-button
                    v-if="canSuspend"
                    variant="warning"
                    @click="onClickSuspend"
                >
                    Suspend
                </b-button>
                <b-button
                    v-if="canPublish"
                    variant="success"
                    @click="onClickPublish"
                >
                    Publish
                </b-button>
            </b-button-group>
        </b-button-toolbar>

        <b-table-simple
            small
        >
            <b-tbody>
                <b-tr
                    v-for="field of fields"
                    :key="field.key"
                >
                    <b-td><strong>{{ field.label }}</strong></b-td>
                    <b-td>
                        <template v-if="firm[field.key] === null">
                            <span class="special">&lt;NULL&gt;</span>
                        </template>

                        <template v-else-if="field.type === 'datetime'">
                            {{ firm[field.key] | date }}
                        </template>

                        <template v-else-if="field.type === 'image'">
                            <b-img
                                :src="firm[field.key]"
                                fluid
                                alt="Responsive image"
                            />
                        </template>

                        <template v-else-if="field.type === 'country'">
                            {{ firm[field.key] | country }}
                        </template>

                        <template v-else-if="field.type === 'region'">
                            {{ firm[field.key] | region(firm.country) }}
                        </template>

                        <template v-else-if="field.type === 'string' && firm[field.key] === ''">
                            <span class="special">&lt;BLANK&gt;</span>
                        </template>

                        <template v-else-if="field.type === 'services'">
                            {{ firmServices }}
                        </template>

                        <template v-else-if="field.type === 'specializations'">
                            {{ firmSpecializations }}
                        </template>

                        <template v-else-if="field.type === 'profile-state'">
                            {{ firmProfileState[firm[field.key]] || firm[field.key] }}
                        </template>

                        <template v-else>
                            {{ firm[field.key] }}
                        </template>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <h3>Images</h3>
        <b-table-simple
            class="images-table"
            small
        >
            <b-thead>
                <b-tr>
                    <b-th>Kind</b-th>
                    <b-th>Caption</b-th>
                    <b-th>Position</b-th>
                    <b-th>Service</b-th>
                    <b-th>Specialization</b-th>
                    <b-th>Image</b-th>
                </b-tr>
            </b-thead>

            <b-tbody>
                <b-tr
                    v-for="image in firm.images"
                    :key="image.id"
                >
                    <b-td style="vertical-align: middle;">{{ image.kind }}</b-td>

                    <b-td style="vertical-align: middle;">{{ image.caption }}</b-td>

                    <b-td style="vertical-align: middle;">{{ image.position }}</b-td>

                    <b-td style="vertical-align: middle;">
                        <span
                            v-if="!image.service"
                        >
                            &mdash;
                        </span>

                        <router-link
                            v-else
                            :to="{ name: 'admin-show-service', params: { id: image.service.id } }"
                        >
                            {{ image.service.parent ? `${image.service.parent.name}: ${image.service.name}` : image.service.name }}
                        </router-link>
                    </b-td>

                    <b-td style="vertical-align: middle;">
                        <span
                            v-if="!image.specialization"
                        >
                            &mdash;
                        </span>

                        <router-link
                            v-else
                            :to="{ name: 'admin-show-specialization', params: { id: image.specialization.id } }"
                        >
                            {{ image.specialization.parent ? `${image.specialization.parent.name}: ${image.specialization.name}` : image.specialization.name }}
                        </router-link>
                    </b-td>

                    <b-td style="vertical-align: middle;">
                        <b-img
                            :src="image.image_path"
                            fluid
                            thumbnail
                        />
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <h3>Admin Edits</h3>
        <b-table-simple
            small
        >
            <b-thead>
                <b-tr>
                    <b-th>Admin</b-th>
                    <b-th>Column</b-th>
                    <b-th>Old Value</b-th>
                    <b-th>New Value</b-th>
                    <b-th>Time</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr
                    v-for="edit in firm.admin_edits"
                    :key="edit.id"
                >
                    <b-td>{{ edit.admin.username }}</b-td>
                    <b-td>{{ edit.column }}</b-td>
                    <b-td>{{ edit.old_value ? edit.old_value : "NULL" }}</b-td>
                    <b-td>{{ edit.new_value ? edit.new_value : "NULL" }}</b-td>
                    <b-td>{{ edit.created_at | date }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import {
    getFirm,
    saveFirm,
    deleteFirm
} from "api/admin/firms"
import { firmProfileState } from "enums/profile-states"

export default {
    data () {
        return {
            fields: [
                { key: "id", label: "ID", type: "number" },
                { key: "tier", label: "Tier", type: "number" },
                { key: "profile_state", label: "Profile State", type: "profile-state" },
                { key: "company_name", label: "Company Name", type: "string" },
                { key: "email", label: "Email", type: "string" },
                { key: "phone", label: "Phone", type: "string" },
                { key: "country", label: "Country", type: "country" },
                { key: "state", label: "State", type: "region" },
                { key: "zip", label: "Zip", type: "string" },
                { key: "city", label: "City", type: "string" },
                { key: "address", label: "Address", type: "string" },
                { key: "site", label: "Site", type: "string" },
                { key: "firm_services", label: "Services", type: "services" },
                { key: "firm_specializations", label: "Specializations", type: "specializations" },
                // { key: "keyword_list", label: "Keywords", type: "string" },
                // { key: "employees", label: "Employees", type: "number" },
                // { key: "offices", label: "Offices", type: "number" },
                // { key: "established", label: "Established", type: "number" },
                // { key: "profile_properties", label: "Properties", type: "properties" },
                { key: "approved_at", label: "Approved", type: "datetime" },
                { key: "created_at", label: "Created", type: "datetime" },
                { key: "updated_at", label: "Updated", type: "datetime" }
            ],
            firm: {
                company_name: "",
                firm_services: [],
                firm_specializations: [],
                profile_state: null,
            },
            firmProfileState,
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),

        firmServices () {
            return this.firm.firm_services.map(firmService => {
                return firmService.service.name
            }).join(", ")
        },

        firmSpecializations () {
            return this.firm.firm_specializations.map(firmSpecialization => {
                return firmSpecialization.specialization.parent && firmSpecialization.specialization.category_name
                    ? `${firmSpecialization.specialization.parent.category_name}: ${firmSpecialization.specialization.name}`
                    : firmSpecialization.specialization.name
            }).join(", ")
        },

        isApprovalPending () {
            return ["first-submitted", "second-submitted", "third-submitted"].includes(this.firm.profile_state)
        },

        canSuspend () {
            return this.firm.profile_state === "published"
        },

        canPublish () {
            return this.firm.profile_state === "suspended"
        },
    },

    mounted () {
        this.loadFirmProfile()
    },

    methods: {
        loadFirmProfile () {
            console.log(this.$route.params.id)
            getFirm(this.adminToken, { id: this.$route.params.id })
                .then(result => {
                    Object.assign(this.firm, result)
                    console.log(this.firm)
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to load firm",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onClickDelete () {
            const message = `Are you sure you want to delete “${ this.firm.company_name }”? This firm will be deleted immediately. You can't undo this action.`

            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
                .then(isConfirmed => {
                    if (isConfirmed) {
                        deleteFirm(this.adminToken, this.firm)
                            .then(() => {
                                // this.$bvToast.toast("Firm deleted.", {
                                //     title: "Success",
                                //     variant: "success",
                                //     autoHideDelay: 5000
                                // })
                                this.$nextTick(() => {
                                    this.$router.push({ name: "admin-firms" })
                                })
                            })
                            .catch(error => {
                                this.$bvToast.toast(error.message, {
                                    title: "Unexpected Error",
                                    variant: "danger",
                                    autoHideDelay: 5000
                                })
                            })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unknown error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        updateProfileState (profileState) {
            const record = {
                id: this.firm.id,
                profile_state: profileState
            }
            saveFirm(this.adminToken, record)
                .then(result => {
                    Object.assign(this.firm, result)
                    this.$bvToast.toast("Succeeded!", {
                        title: "Save status",
                        variant: "success",
                        autoHideDelay: 5000
                    })
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onClickApprove () {
            let newProfileState = null
            switch (this.firm.profile_state) {
            case "first-submitted":
                newProfileState = "first-approved"
                break
            case "second-submitted":
                newProfileState = "second-approved"
                break
            case "third-submitted":
                newProfileState = "published"
                break
            default:
                break
            }
            if (newProfileState) {
                this.updateProfileState(newProfileState)
            }
        },

        onClickDeny () {
            let newProfileState = null
            switch (this.firm.profile_state) {
            case "first-submitted":
                newProfileState = "first-saved"
                break
            case "second-submitted":
                newProfileState = "second-saved"
                break
            case "third-submitted":
                newProfileState = "third-saved"
                break
            default:
                break
            }
            if (newProfileState) {
                this.updateProfileState(newProfileState)
            }
        },

        onClickSuspend () {
            this.updateProfileState("suspended")
        },

        onClickPublish () {
            this.updateProfileState("published")
        }        
    }
}
</script>

<style lang="scss" scoped>
.special {
    background-color: #ccc;
    font-weight: bold;
    font-size: 14px;
}

.images-table {
    .td {
        vertical-align: middle;
    }
}
</style>
