<template>
    <footer class="footer pt-3 my-md-4 pt-md-3 d-print-none px-md-2" style="border-top: 1px solid #a0a0a0 !important;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6 col-md">
                    <h5>Products</h5>
                    <ul class="list-unstyled text-small">
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'products-directory-index' }"
                            >
                                Building Products Library
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'product-csi-masterformat-index-page' }"
                            >
                                CSI MasterFormat Index
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'product-all-types-page' }"
                            >
                                Product Types
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'product-company-name-page' }"
                            >
                                Manufacturer Names
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'product-leed-page' }"
                            >
                                Green Products
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'showcase-your-products' }"
                            >
                                Showcase your Products
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'settings-applications-manufacturer' }"
                            >
                                Manufacturer Admin Login
                            </b-link>
                        </li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h5>Professionals</h5>
                    <ul class="list-unstyled text-small">
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'design-firms', query: { country: 'us' } }"
                            >
                                Design Firm Directory
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'design-firms'}"
                            >
                                Service Types
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'firm-directory', params: { directoryType: 'all-locations' } }"
                            >
                                Location
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'firm-directory', params: { directoryType: 'specialization' } }"
                            >
                                Specialization
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'firm-directory', params: { directoryType: 'company-name' } }"
                            >
                                Firm names
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'firm-application-confirmation' }"
                            >
                                Profile Your Firm
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'settings-applications-firm' }"
                            >
                                Design Firm Admin Login
                            </b-link>
                        </li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h5>Newsletters +</h5>
                    <ul class="list-unstyled text-small">
                        <li>
                            <a class="footer-link" href="/aec-news">News Index</a>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'industry-news' }"
                            >
                                AEC Industry News
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'events' }"
                            >
                                Events&nbsp;+&nbsp;Expos
                            </b-link>
                        <!--                          <a class="footer-link" href="#">Events&nbsp;+&nbsp;Expos</a></li>-->
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'building-product-showcase' }"
                            >
                                Product Showcase
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'learning-center' }"
                            >
                                CEU Learning Center
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'videos' }"
                            >
                                Building & Design Videos
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                @click="$bvModal.show('newsletter-subscription-modal')"
                            >
                                Sign Up for Newsletters
                            </b-link>
                        </li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h5>Administration</h5>
                    <ul class="list-unstyled text-small">
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'about-dg' }"
                            >
                                About DG
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'contact-us' }"
                            >
                                Contact Us
                            </b-link>
                        </li>

                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'settings-applications-manufacturer' }"
                            >
                                Manufacturer Admin
                            </b-link>
                        </li>

                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'settings-applications-firm' }"
                            >
                                Design Firm Admin
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                @click="$bvModal.show('become_a_member')"
                            >
                                Subscriber Services
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'become-a-member' }"
                            >
                                New Membership
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'contact-us' }"
                            >
                                Feedback & Comments
                            </b-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>    
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    .footer {
        h5 {
            font-family: Helvetica, Sans-Serif;
            font-size: 22px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.09;
            letter-spacing: 0.14px;
            color: #000000;            
        }

        .footer-link {
            font-family: 'Helvetica Neue Light';
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: 1.5px;
            color: #000000;
        }
    }
</style>
