<template>
    <div class="content">
        <div class="title">
            <h1>CEU Learning Center</h1>
        </div>
        
        <div class="content-tabs">
            <b-row>
                <b-col>
                    <b-button-toolbar
                        aria-label="Actions"
                        key-nav
                        class="mb-3"
                    >
                        <b-button-group class="mx-1">
                            <b-button
                                :to="{ name: 'admin-new-ceu-learning-center' }"
                            >
                                New CEU Leaning Center
                            </b-button>
                        </b-button-group>
                    </b-button-toolbar>
                </b-col>
                <b-col class="d-flex justify-content-end">
                    <b-pagination-nav
                        v-model="currentPage"
                        :number-of-pages="lastPage"
                        :limit="7"
                        :to="{ name: 'admin-ceu-learning-centers' }"
                        :link-gen="linkGen"
                        :disabled="paginationDisable"
                        @page-click="onPageClick"
                    />
                </b-col>
            </b-row>

            <b-table
                id="index-table"
                :fields="fields"
                :items="items"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-debounce="1000"
                table-class=""
                primary-key="id"
                stacked="md"
                show-empty
                small
                striped
                hover
                responsive
                @row-clicked="onClickRow"
            >
                <template #cell(manufacturer)="row">
                    {{ row.item.profile.company_name | NA }}
                </template>

                <template #cell(active)="row">
                    {{ row.item.active ? 'Yes' : 'No' }}
                </template>

                <template #cell(created_at)="row">
                    {{ row.item.created_at | date }}
                </template>

                <template #cell(updated_at)="row">
                    {{ row.item.updated_at | date }}
                </template>
            </b-table>

            <div class="d-flex justify-content-end">
                <b-pagination-nav
                    v-model="currentPage"
                    :number-of-pages="lastPage"
                    :limit="7"
                    :to="{ name: 'admin-ceu-learning-centers' }"
                    :link-gen="linkGen"
                    :disabled="paginationDisable"
                    @page-click="onPageClick"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { ApiError } from "api/base"
import { getCourses } from "api/admin/courses"
import { mapActions, mapGetters } from "vuex"
import AdminPaginationMixin from "../../../mixins/admin_pagination.js"

export default {
    mixins: [AdminPaginationMixin],
    data () {
        return {
            paginationDisable: false,
            items: [],
            totalItems: 0,
            itemsPerPage: 25,
            sortBy: "",
            sortDesc: false,
            fields: [
                { key: "id", label: "ID", sortable: true, class: "text-nowrap column-left" },
                { key: "subject", label: "Subject", sortable: true, class: "text-nowrap column-left" },
                { key: "manufacturer", label: "Manufacturer", sortable: true, class: "text-nowrap" },
                { key: "active", label: "Active", sortable: true, class: "text-nowrap column-left column-center" },
                { key: "created_at", label: "Created", sortable: true, class: "text-nowrap column-center" },
                { key: "updated_at", label: "Updated", sortable: true, class: "text-nowrap column-center" }
            ],
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    watch: {
        "$route": "loadCourses",

        sortBy (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortBy: newValue,
                sortDesc: this.sortDesc
            })
            this.$router.push({ name: this.$route.name, query })
        },

        sortDesc (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortDesc: newValue,
                sortBy: this.sortBy
            })
            this.$router.push({ name: this.$route.name, query })
        },
    },

    mounted () {
        this.loadCourses()
    },

    methods: {
        ...mapActions("account", ["adminSignout"]),

        onSearch () {
            this.loadCourses();
        },
        
        linkGen () {
            return {
                path: this.$route.path,
                query: Object.assign({}, this.$route.query)
            }
        },

        loadCourses () {
            // this.page = this.$route.query.page || 1
            this.sortBy = this.$route.query.sortBy || ""
            this.sortDesc = !!this.$route.query.sortDesc
            getCourses(this.adminToken, {
                page: this.currentPage,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                query: this.$route.query.query
            })
                .then(result => {
                    this.currentPage = result.meta.page
                    this.totalItems = result.meta.total_count
                    this.lastPage = result.meta.total_pages
                    this.itemsPerPage = result.meta.per_page
                    this.items.splice(0, this.items.length, ...result.data)
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        this.$bvToast.toast(error.message, {
                            title: "Unexpected Error",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    }
                })
        },

        onClickRow (item) {
            this.$router.push({
                name: "admin-edit-ceu-learning-center",
                params: { id: item.id }
            })
        },
    }
}
</script>

<style lang="scss">
#index-table {
    th {
        white-space: nowrap !important;

        div {
            white-space: nowrap !important;            
        }
    }
}
.title {     
    padding-top: 1rem;
    width: -webkit-fill-available;
    position: fixed;
    background-color: #fff;
    z-index: 20;
}

.content-tabs { 
    position: relative;
    top: 70px;
}
</style>