import Vue from "vue"
import moment from "moment-timezone"

Vue.filter("date", (value, format = "MM-DD-YY") => {
    if(value){
        return moment.utc(value).format(format)
    }else{
        return ""
    }
})

Vue.filter("datetime", (value, format = "MMM Do, YYYY h:mm A") => {
    return moment(value).format(format)
})

Vue.filter("dateUTC", (value, format = "MM-DD-YY") => {
    if(value){
        return moment.utc(value).format(format)
    }else{
        return ""
    }
})

Vue.filter("datetimeUTC", (value, format = "MMM Do, YYYY h:mm A") => {
    return moment(value).format(format)
})


Vue.filter("addDaysToDate", (value, days, format = "MMM Do, YYYY h:mm A") => {
    return moment(value).add(days, 'days').format(format)
})
