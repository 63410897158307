<template>
    <div class="content">
        <div class="title">
            <h1>Videos</h1>
        </div>
        
        <div class="content-tabs">
            <b-tabs
                v-model="currentTab"
                content-class="mt-3"
            >
                <b-tab :title="videosTab" active />
                <b-tab :title="featuredVideosTab" />
            </b-tabs>

            <b-row>
                <b-col>
                    <b-button-toolbar
                        aria-label="Actions"
                        key-nav
                        class="mb-3"
                    >
                        <b-button-group class="mx-1">
                            <b-button
                                v-if="currentTab == 0"
                                :to="{ name: 'admin-new-video' }"
                            >
                                New Video
                            </b-button>
                            <b-button
                                v-else
                                :to="{ name: 'admin-new-featured-video' }"
                            >
                                New Featured Video
                            </b-button>
                        </b-button-group>
                    </b-button-toolbar>
                </b-col>
                <b-col class="d-flex justify-content-end">
                    <b-pagination-nav
                        v-model="currentPage"
                        :number-of-pages="lastPage"
                        :limit="7"
                        :to="{ name: 'admin-videos' }"
                        :link-gen="linkGen"
                        :disabled="paginationDisable"
                        @page-click="onPageClick"
                    />
                </b-col>
            </b-row>

            <b-table
                id="index-table"
                :fields="fields"
                :items="items"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-debounce="1000"
                primary-key="id"
                stacked="md"
                show-empty
                small
                striped
                hover
                @row-clicked="onClickRow"
                :busy.sync="paginationDisable"
            >
                <template #table-busy>
                    <div class="text-center my-2" style="color: #343a40;">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template #cell(duration)="row">
                    {{ row.item.duration | duration }}
                </template>
                <template #cell(image_path)="row">
                    <b-img
                        :src="row.item.image_path || defaultCover"
                        fluid
                        thumbnail
                        width="100"
                        class="image"
                    />
                </template>
                <template #cell(created_at)="row">
                    {{ row.item.created_at | date }}
                </template>
                <template #cell(updated_at)="row">
                    {{ row.item.updated_at | date }}
                </template>
                <template #cell(url)="row">
                    <a :href="row.item.url" target="_blank">{{row.item.url}}</a>
                </template>
                <template #cell(approval)="row">
                    <b-button
                        size="sm"
                        variant="danger"
                        @click.prevent="onClickDelete(row.item.id)"
                    >
                        Remove
                    </b-button>
                </template>
            </b-table>

            <div class="d-flex justify-content-end">
                <b-pagination-nav
                    v-model="currentPage"
                    :number-of-pages="lastPage"
                    :limit="7"
                    :to="{ name: 'admin-videos' }"
                    :link-gen="linkGen"
                    :disabled="paginationDisable"
                    @page-click="onPageClick"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { ApiError } from "api/base"
import { getVideos, deleteVideo } from "api/admin/videos"
import { getFeaturedVideos } from "api/admin/featured-videos"
import { mapActions, mapGetters } from "vuex"
import AdminPaginationMixin from "../../../mixins/admin_pagination.js"

export default {
    filters: {
        duration (value) {
            const hours = ~~(value / 3600)
            const seconds = value % 60
            if (hours === 0) {
                const minutes = ~~(value / 60)
                return `${minutes<10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
            } else {
                const minutes = ~~((value - hours * 3600) / 60)
                return `${hours<10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
            }
        }
    },
    mixins: [AdminPaginationMixin],
    data () {
        return {
            defaultCover: require("assets/images/video.png"),
            currentTab: 0,
            paginationDisable: false,
            items: [],
            totalItems: 0,
            featuredCount: 0,
            itemsPerPage: 25,
            sortBy: "",
            sortDesc: false,
            fields: [
                { key: "id", label: "ID", sortable: true, class: "column-left", thStyle: { width: "50px"} },
                { key: "image_path", label: "Image", sortable: true, class: "column-right", thStyle: { width: "120px"} },
                { key: "duration", label: "Duration", sortable: true, class: "column-right", thStyle: { width: "70px"} },
                { key: "title", label: "Title", sortable: true, class: "column-left", thStyle: { width: "200px"} },
                { key: "website", label: "Source", sortable: true, class: "column-left", thStyle: { width: "100px"} },
                { key: "url", label: "URL", sortable: true, class: "column-left", thStyle: { width: "200px"} },
                { key: "created_at", label: "Date Added", sortable: true, class: "column-center", thStyle: { width: "100px"} },
                { key: "approval", label: "Action", sortable: false, class: "column-center", thStyle: { width: "100px"} },
            ],
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
        videosTab () {
            return `Videos (${this.totalItems})`
        },

        featuredVideosTab () {
            return `Featured Videos (${this.featuredCount})`
        },
    },

    watch: {
        "$route": "loadVideos",

        sortBy (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortBy: newValue,
                sortDesc: this.sortDesc
            })
            this.$router.push({ name: this.$route.name, query })
        },

        sortDesc (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortDesc: newValue,
                sortBy: this.sortBy
            })
            this.$router.push({ name: this.$route.name, query })
        },
        currentTab (newValue) {
            this.loadVideos()
        }
    },

    mounted () {
        this.loadVideos()
    },

    methods: {
        ...mapActions("account", ["adminSignout"]),

        onClickDelete (id) {
            const message = `Are you sure you want to delete “${ id }”? This record will be deleted immediately. You can't undo this action.`

            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
                .then(isConfirmed => {
                    if (isConfirmed) {
                        deleteVideo(this.adminToken, id)
                            .then(() => {
                                this.$bvToast.toast("Deleted!", {
                                    title: "Successfully Deleted!",
                                    variant: "success",
                                    autoHideDelay: 5000
                                })
                                this.$router.go();
                            })
                            .catch(error => {
                                this.$bvToast.toast(error.message, {
                                    title: "Unexpected Error",
                                    variant: "danger",
                                    autoHideDelay: 5000
                                })
                            })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unknown error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onSearch () {
            this.loadVideos();
        },
        
        linkGen () {
            return {
                path: this.$route.path,
                query: Object.assign({}, this.$route.query)
            }
        },

        loadVideos () {
            // this.page = this.$route.query.page || 1
            this.sortBy = this.$route.query.sortBy || ""
            this.sortDesc = !!this.$route.query.sortDesc
            this.paginationDisable = true;
            if(this.currentTab == 0){
                getVideos(this.adminToken, {
                    page: this.currentPage,
                    sortBy: this.sortBy || 'created_at',
                    sortDesc: this.sortDesc || 'desc',
                    query: this.$route.query.query
                })
                    .then(result => {
                        this.currentPage = result.meta.page
                        this.totalItems = result.meta.total_count
                        this.featuredCount = result.meta2.total_count
                        this.lastPage = result.meta.total_pages
                        this.itemsPerPage = result.meta.per_page
                        this.items.splice(0, this.items.length, ...result.data)
                        this.paginationDisable = false;
                    })
                    .catch(error => {
                        if (error instanceof ApiError && error.status === 401) {
                            // Not authorized
                            this.adminSignout(null)
                            this.$router.go()
                        } else {
                            this.$bvToast.toast(error.message, {
                                title: "Unexpected Error",
                                variant: "danger",
                                autoHideDelay: 5000
                            })
                        }
                    })
                }else{
                    getFeaturedVideos(this.adminToken, {
                        page: this.currentPage,
                        sortBy: this.sortBy || 'created_at',
                        sortDesc: this.sortDesc || 'desc',
                        query: this.$route.query.query
                    })
                        .then(result => {
                            this.currentPage = result.meta.page
                            this.featuredCount = result.meta.total_count
                            this.totalItems = result.meta2.total_count
                            this.lastPage = result.meta.total_pages
                            this.itemsPerPage = result.meta.per_page
                            this.items.splice(0, this.items.length, ...result.data)
                            this.paginationDisable = false;
                        })
                        .catch(error => {
                            if (error instanceof ApiError && error.status === 401) {
                                // Not authorized
                                this.adminSignout(null)
                                this.$router.go()
                            } else {
                                this.$bvToast.toast(error.message, {
                                    title: "Unexpected Error",
                                    variant: "danger",
                                    autoHideDelay: 5000
                                })
                            }
                        })
                }
        },

        onClickRow (item) {
            if(this.currentTab == 0){
                this.$router.push({
                    name: "admin-edit-video",
                    params: { id: item.id }
                })
            }else{
                this.$router.push({
                    name: "admin-edit-featured-video",
                    params: { id: item.id }
                })
            }
        },
    }
}
</script>

<style lang="scss">
#index-table {
    th {
        white-space: nowrap !important;

        div {
            white-space: nowrap !important;            
        }
    }
}
.title {     
    padding-top: 1rem;
    width: -webkit-fill-available;
    position: fixed;
    background-color: #fff;
    z-index: 20;
}

.content-tabs { 
    position: relative;
    top: 70px;
}
</style>
